import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AccountService } from '@core/services';
import { tapResponse } from '@shared/utils';
import { randexp } from 'randexp';
import { ScNotifierService } from '@sceon/notifier';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lib-update-ops-password-dialog',
  templateUrl: './update-ops-password-dialog.component.html',
  styleUrls: ['./update-ops-password-dialog.component.scss'],
})
export class UpdateOpsPasswordDialogComponent implements OnInit {
  processing: boolean = false;
  form: UntypedFormGroup;

  constructor(
    private matDialogRef: MatDialogRef<
      UpdateOpsPasswordDialogComponent,
      boolean
    >,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private notifier: ScNotifierService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  get passwordControl() {
    return this.form.get('password');
  }

  get newPasswordControl() {
    return this.form.get('new_password');
  }

  get confirmPasswordControl() {
    return this.form.get('confirmPassword');
  }

  generatePass() {
    const generatedPass = randexp(
      /^(\d{4})([a-z]{2})([A-Z]{4})[!@#$%^&*\-_?]{2}$/
    );

    this.newPasswordControl.setValue(generatedPass);
    this.confirmPasswordControl.setValue(generatedPass);
  }

  submit() {
    if (this.confirmPasswordControl.value !== this.newPasswordControl.value) {
      this.confirmPasswordControl.setErrors({
        differentPassword:
          'Confirmation password do not match the new password',
      });
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.processing = true;

    this.accountService
      .updatePassword(this.passwordControl.value, this.newPasswordControl.value)
      .pipe(
        tapResponse(
          ({ result }) => {
            // notify success
            this.notifier.success({
              title: 'Success',
              text: 'Password has successfully been changed',
            });
            this.matDialogRef.close(true);
          },
          (error: Error) => {
            this.notifier.error({
              title: 'Error',
              text: 'Something went wrong',
            });
            this.matDialogRef.close(false);
          }
        )
      )
      .subscribe();
  }
}
