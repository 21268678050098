import { InjectionToken, ValueProvider } from '@angular/core';
import { ScFormErrorsMap } from './types';

export type ScFormErrorMsgConfig = ScFormErrorsMap;

export const SC_FORM_ERROR_MSG_CONFIG =
  new InjectionToken<ScFormErrorMsgConfig>('SC_FORM_ERROR_MESSAGE_CONFIG');

export function provideErrorMsgHashMap(map: ScFormErrorsMap): ValueProvider {
  return {
    provide: SC_FORM_ERROR_MSG_CONFIG,
    useValue: map,
    multi: true,
  };
}
