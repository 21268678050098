import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { HashMap, Translation, TranslocoLoader } from '@ngneat/transloco';

import { DictionaryCategories, GlobalEnabledLanguage } from '@gql-types';
import {
  DictionaryListByLangGQL,
  DictionaryFragment,
} from './_graphql/dictionaryList';

@Injectable({
  providedIn: 'root',
})
export class TranslocoGqlLoaderService implements TranslocoLoader {
  readonly categories = [
    DictionaryCategories.Cellar,
    DictionaryCategories.System,
    DictionaryCategories.Geography,
    DictionaryCategories.SwitzerlandGeography,
  ];

  readonly dictionaryList$ = this.dictionaryListByLangGQL
    .fetch({ categories: this.categories })
    .pipe(
      map(({ data }) => data.public.dictionaryList),
      catchError((error: Error) => of([])),
      shareReplay(1)
    );

  constructor(private dictionaryListByLangGQL: DictionaryListByLangGQL) {}

  getTranslation(lang: GlobalEnabledLanguage): Observable<Translation> {
    return this.dictionaryList$.pipe(
      map((dictionaryList) =>
        this.createHashMapFromDictList(lang, dictionaryList)
      )
    );
  }

  private createHashMapFromDictList(
    lang: GlobalEnabledLanguage,
    data: DictionaryFragment[]
  ): HashMap<string> {
    const hashMap: HashMap<string> = {};

    for (let i = 0, len = data.length; i < len; ++i) {
      const item = data[i];
      hashMap[item.key] = item[`value_${lang.toLowerCase()}`];
    }

    return hashMap;
  }
}
