import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedConfigActions, OpsAccountActions } from '@core/root-store';
import {
  OpsPrivateMessageFragment,
  PrivateMessageGQL,
} from './services/_graphql/private-messages';
import { ScNotifierService, ScNotifierTheme } from '@sceon/notifier';
import { Router } from '@angular/router';
import { AccountService } from './services';
import { tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'lib-core',
  template: `
    <lib-layout>
      <router-outlet></router-outlet>
    </lib-layout>
  `,
})
export class CoreComponent implements OnInit {
  opsAccounts$ = this.accountService.opsAccounts$;

  constructor(
    private store: Store,
    private privateMessage: PrivateMessageGQL,
    private scNotifier: ScNotifierService,
    private accountService: AccountService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.dispatch(SharedConfigActions.loadPaymentMethods());
    this.store.dispatch(OpsAccountActions.updateLastActiveDate());

    this.privateMessage
      .subscribe()
      .pipe(
        withLatestFrom(this.opsAccounts$),
        tap(([res, opsAccounts]) => {
          const data = res.data.opsToolsPrivateMessageCreatedForWS;
          const messageFrom = opsAccounts.find(
            (account) => account.id === data.sender_account_id
          )?.showname;

          this.showPrivateMessageNotification(data, messageFrom);
        })
      )
      .subscribe();
  }

  showPrivateMessageNotification(
    privateMessage: OpsPrivateMessageFragment,
    messageFrom: string
  ) {
    this.scNotifier.show(
      {
        title: `Message from ${messageFrom}`,
        text: privateMessage.subject,
        theme: privateMessage.is_urgent
          ? ScNotifierTheme.Danger
          : ScNotifierTheme.Warning,

        confirm: {
          submitBtn: true,
          submitBtnText: 'view',
          cancelBtn: true,
          submitBtnHandler: () => {
            this.router.navigate(['./ops-tools/private-messages']);
          },
        },
      },
      {
        duration: Infinity,
      }
    );
  }
}
