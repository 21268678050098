import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { GraphqlWebsocketStatus } from '@libs/core/src/lib/types';
import { State, graphqlWebsocketQuery } from '../root-store/reducers';
import { GqlWsActions } from '../root-store/actions';

@Injectable({
  providedIn: 'root',
})
export class GqlWsService {
  connectionState$ = this.store.pipe(
    select(graphqlWebsocketQuery.selectGraphqlWebsocketState)
  );

  constructor(private store: Store<State>) {}

  init(error: string | null) {
    this.store.dispatch(
      GqlWsActions.websocketStatusChange({
        status: GraphqlWebsocketStatus.Init,
        error,
      })
    );
  }

  connected() {
    this.store.dispatch(
      GqlWsActions.websocketStatusChange({
        status: GraphqlWebsocketStatus.Connected,
        error: null,
      })
    );
  }

  reconnected() {
    this.store.dispatch(
      GqlWsActions.websocketStatusChange({
        status: GraphqlWebsocketStatus.Reconnected,
        error: null,
      })
    );
  }

  disconnected() {
    this.store.dispatch(
      GqlWsActions.websocketStatusChange({
        status: GraphqlWebsocketStatus.Disconnected,
        error: null,
      })
    );
  }

  error(error: string) {
    this.store.dispatch(
      GqlWsActions.websocketStatusChange({
        status: GraphqlWebsocketStatus.Error,
        error,
      })
    );
  }
}
