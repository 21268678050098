import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[popover]',
})
export class PopoverDirective implements OnInit, OnDestroy {
  @Input() popover: any;
  @Input() hideOnHover: boolean = true;
  @Input() horizontalAlignment: 'left' | 'right' | 'center' = 'right';
  @Input() verticalAlignment: 'top' | 'bottom' | 'center' = 'bottom';
  @Input() offsetX: number = 0;
  @Input() offsetY: number = 0;
  @Input()
  private overlayRef!: OverlayRef;
  private positionStrategy: FlexibleConnectedPositionStrategy;
  private _destroyed = new Subject<void>();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (this.popover) {
      this.createOverlay();
      this.listenMouseMoveChanges();
    }
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this._destroyed.next();
    this._destroyed.complete();
  }

  private createOverlay(): void {
    const left = this.horizontalAlignment === 'left';
    const right =
      !this.horizontalAlignment || this.horizontalAlignment === 'right';
    const top = !this.verticalAlignment || this.verticalAlignment === 'top';
    const verticalCenter = this.verticalAlignment === 'center';

    const scrollStrategy = this.overlay.scrollStrategies.block();

    this.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el.nativeElement.parentElement)
      .withPositions([
        {
          originX: left ? 'start' : right ? 'end' : 'center',
          overlayX: left ? 'end' : right ? 'start' : 'center',
          originY: top ? 'bottom' : verticalCenter ? 'center' : 'top',
          overlayY: top ? 'bottom' : verticalCenter ? 'center' : 'top',
          offsetX: this.offsetX,
          offsetY: this.offsetY,
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy,
      scrollStrategy,
    });
  }

  private attachOverlay(evt: MouseEvent): void {
    if (!this.overlayRef?.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.popover,
        this._viewContainerRef
      );

      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  private listenMouseMoveChanges(): void {
    fromEvent(this.el.nativeElement, 'mousemove')
      .pipe(takeUntil(this._destroyed))
      .subscribe((evt: MouseEvent) => this.attachOverlay(evt));

    fromEvent(this.el.nativeElement.ownerDocument, 'mousemove')
      .pipe(
        filter(() => !!this.overlayRef?.hasAttached()),
        takeUntil(this._destroyed)
      )
      .subscribe((evt: MouseEvent) => {
        if (
          !this.el.nativeElement.contains(evt.target as HTMLElement) &&
          (!this.overlayRef.overlayElement.contains(
            evt.target as HTMLElement
          ) ||
            this.hideOnHover)
        ) {
          this.detachOverlay();
        } else {
          this.overlayRef.updatePosition();
        }
      });
  }
}
