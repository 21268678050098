// Angular specific
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Third party
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';

// App specific
import { ScPipesModule } from '@shared/pipes';

// Module specific
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SearchbarModule } from './searchbar';
import { UpdateOpsPasswordModule } from '@shared/update-ops-password';
import { ScDirectivesModule } from '@shared/directives';
import { SubheaderComponent } from './subheader/subheader.component';
import { HasOverflowDirective } from './sidenav/has-overflow.directive';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidenavComponent,
    SubheaderComponent,
    HasOverflowDirective,
  ],
  exports: [LayoutComponent, SubheaderComponent],
  imports: [
    // Angular specific
    CommonModule,
    RouterModule,
    SearchbarModule,
    ScPipesModule,

    // Third party
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    NgScrollbarModule,
    ScDirectivesModule,

    UpdateOpsPasswordModule,
  ],
})
export class LayoutModule {}
