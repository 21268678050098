import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { UpdateOpsPasswordDialogComponent } from './update-ops-password-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UpdateOpsPasswordService {
  constructor(private matDialog: MatDialog) {}

  openUpdatePassDialog(): Observable<{ changed: boolean }> {
    return this.matDialog
      .open<UpdateOpsPasswordDialogComponent, {}, boolean>(
        UpdateOpsPasswordDialogComponent,
        {
          data: {},
          maxWidth: '345px',
        }
      )
      .afterClosed()
      .pipe(map((res) => ({ changed: !!res })));
  }
}
