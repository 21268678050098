import { NgModule, ValueProvider, ViewContainerRef } from '@angular/core';

import {
  GalleryModule,
  GALLERY_CONFIG,
  GalleryConfig,
  ImageSize,
} from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

import { ScGalleryViewer } from './gallery-viewer.service';
import { GalleryViewerComponent } from './gallery-viewer.component';
import { CommonModule } from '@angular/common';
import { PinchZoomModule } from '@shared/pinch-zoom';

export const SC_GALLERY_CONFIG_PROVIDER: ValueProvider = {
  provide: GALLERY_CONFIG,
  useValue: {
    imageSize: ImageSize.Contain,
    thumb: false,
  } as GalleryConfig,
};

@NgModule({
  imports: [GalleryModule, LightboxModule, PinchZoomModule, CommonModule],
  exports: [GalleryModule, LightboxModule],
  declarations: [GalleryViewerComponent],
  providers: [ScGalleryViewer],
})
export class ScGalleryViewerModule {}
