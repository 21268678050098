import { Component, Inject, OnInit } from '@angular/core';
import { filter, take, tap } from 'rxjs/operators';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { AccountService } from '@libs/core/src/lib/services/account.service';
import {
  CellarAggregatorAccountSearchItem,
  CellarAggregatorProfileSearchItem,
  CellarAggregatorSearchItem,
  CellarAggregatorSearchItemType,
} from '@gql-types';

import { SearchbarStore } from '../searchbar.store';

interface IColumnName {
  [key: string]: (
    | keyof CellarAggregatorProfileSearchItem
    | keyof CellarAggregatorAccountSearchItem
    | string
  )[];
}

@Component({
  selector: 'lib-searchbar-result-dialog',
  templateUrl: './searchbar-result-dialog.component.html',
  styleUrls: ['./searchbar-result-dialog.component.scss'],
})
export class SearchbarResultDialogComponent implements OnInit {
  searchTypes = Object.values(CellarAggregatorSearchItemType);

  searchQuery$ = this.searchbarStore.searchQuery$.pipe(take(1));

  displayedColumns: IColumnName = {
    [CellarAggregatorSearchItemType.Account]: [
      'account_id',
      'username',
      'email',
      'role',
      'account_status',
      'ops_account_id',
    ],
    [CellarAggregatorSearchItemType.Profile]: [
      'reference_id',
      'account_id',
      'showname',
      'emails',
      'phone_numbers',
      'profile_type',
      'account_status',
      'is_deleted',
    ],
  };

  data: {
    type: CellarAggregatorSearchItemType;
    displayColumns: string[];
    count: number;
    dataSource: MatTableDataSource<CellarAggregatorSearchItem>;
  }[] = [];

  loading$ = this.searchbarStore.loading$;

  searchResult$ = this.searchbarStore.searchResult$;

  resultCount$ = this.searchbarStore.resultCount$;

  opsAccounts$ = this.accountService.opsAccounts$;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData,
    private matDialogRef: MatDialogRef<any>,
    private searchbarStore: SearchbarStore,
    private accountService: AccountService
  ) {
    this.searchbarStore.searchResult$
      .pipe(tap(() => (this.data = [])))
      .subscribe((result) => {
        this.searchTypes.forEach((type) => {
          this.data = [
            ...this.data,
            {
              type: type,
              count: result[type] ? result[type].length : 0,
              dataSource: new MatTableDataSource(result[type]),
              displayColumns: this.displayedColumns[type],
            },
          ];
        });
      });
  }

  ngOnInit(): void {}
}
