// Angular specific
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterLinkActive } from '@angular/router';
import { asyncScheduler } from 'rxjs';

// Module specific
import { Menu } from '../layout.types';
import { PendingEntitiesCounts } from '../../types/index';
import { LayoutAnimations } from '../layout.animation';
import ItemIdentifier = Menu.ItemIdentifier;
import { OpsRole } from '@gql-types';
import { ScMapperPipeFn } from '@shared/pipes';

@Component({
  selector: 'lib-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    LayoutAnimations.backdropToggle(),
    LayoutAnimations.expandCollapse(),
  ],
})
export class SidenavComponent implements OnChanges {
  private _userItem: Menu.Item;
  private _menuItems: Array<Menu.Item> = [];
  private _showSidenav: boolean;

  @Input()
  @HostBinding('class.toggled')
  set showSidenav(value: boolean) {
    this.noAnimation = !!this.openedDropdownId;
    this._showSidenav = value;
  }
  get showSidenav(): boolean {
    return this._showSidenav;
  }

  @Input()
  set userItem(value: Menu.Item) {
    this._userItem = value;
  }
  get userItem(): Menu.Item {
    return this._userItem;
  }

  @Input()
  set menuItems(value: Array<Menu.Item>) {
    this._menuItems = value;
  }
  get menuItems(): Array<Menu.Item> {
    return this._menuItems;
  }

  @Input() role: OpsRole;

  @Input() badges: PendingEntitiesCounts;

  @Input() openedDropdownId: Menu.ItemIdentifier;

  @Output() toggleDropdownId = new EventEmitter<Menu.ItemIdentifier>();

  @Output() logout = new EventEmitter<void>();

  @Output() closed = new EventEmitter<boolean>();

  @Output() languageChanged = new EventEmitter<void>();

  @ViewChildren(RouterLinkActive, { read: ElementRef })
  linkRefs: QueryList<ElementRef>;

  readonly activeClass = 'active';

  noAnimation: boolean;

  // *ngFor trackBy
  menuItemsTrackByFn: TrackByFunction<Menu.Item> = (
    index: number,
    item: Menu.Item
  ) => item.id;

  emptyFilter: ScMapperPipeFn<string[], string[]> = (arr) => {
    return arr.filter((el) => !!el);
  };

  hasSubsAnyBadgesMapperFn: ScMapperPipeFn<Menu.Item, boolean> = (
    menuItem,
    badges: PendingEntitiesCounts
  ) => {
    return menuItem.subMenu.some(
      (sub: Menu.Item) => sub.badgeIdentifier && badges[sub.badgeIdentifier]
    );
  };

  hasRootAnyBadgesMapperFn: ScMapperPipeFn<Menu.Item, boolean> = (
    menuItem,
    badges: PendingEntitiesCounts
  ) => {
    return badges[menuItem.id];
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showSidenav) {
      this.activeElementAddClass();
    }
  }

  closeMenu() {
    this.closed.emit();
    this.activeElementAddClass();
  }

  toggleOpenedDropDown(id: Menu.ItemIdentifier) {
    this.toggleDropdownId.emit(this.openedDropdownId === id ? null : id);
    this.noAnimation = false;
  }

  handleLogout(id: Menu.ItemIdentifier) {
    if (id === ItemIdentifier.Logout) {
      this.logout.emit();
    }
  }

  private activeElementAddClass() {
    asyncScheduler.schedule(() => {
      const activeEl = this.findActiveLink();
      this.toggleDropdownId.emit(
        activeEl?.getAttribute('identifier') as Menu.ItemIdentifier
      );
    });
  }

  private findActiveLink(): HTMLElement {
    return this.linkRefs
      ?.toArray()
      .find((e) => e.nativeElement.classList.contains(this.activeClass))
      ?.nativeElement;
  }

  readonly hasPermissionMapperFn: ScMapperPipeFn<Menu.Item, boolean> = (
    menu
  ) => {
    if (!this.role || !menu.roles || !menu.roles.length) {
      return true;
    }

    return menu.roles.includes(this.role);
  };
}
