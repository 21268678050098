import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { State } from '../reducers/layout.reducer';
import { AccountService } from '@libs/core/src';
import { LayoutActions } from '@core/root-store';
import { map, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { OpsRole } from '@gql-types';
import { of } from 'rxjs';
import { PendingEntitiesService } from '@core/services/pending-entities.service';

@Injectable()
export class LayoutEffects {
  getBadgeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getBadgeData),
      mergeMap(() => {
        return this.accountService.authAccount$.pipe(
          take(1),
          mergeMap((account) => {
            if (!account) {
              return of({});
            }

            switch (account.role) {
              case OpsRole.Admin:
                return this.pendingEntitiesService.getCountsForAdmins();
              case OpsRole.Sales:
                return this.pendingEntitiesService.getCountsForSales(
                  account.id
                );
              case OpsRole.Manager:
                return this.pendingEntitiesService.getCountsForManagers();
              case OpsRole.Seo:
                return of({});
            }
          }),
          map((entitiesCounts) => {
            return LayoutActions.getBadgeDataSuccess({
              badges: entitiesCounts,
            });
          })
        );
      })
    )
  );

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private accountService: AccountService,
    private pendingEntitiesService: PendingEntitiesService
  ) {}
}
