import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'img[scLazyImage]',
})
export class LazyImageDirective {
  @Input() set scLazyImage(source) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.renderer.setAttribute(entry.target, 'src', source);
          this.renderer.addClass(entry.target, 'entered');
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement);
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background-color',
      '#fafafa'
    );
  }
}
