import { EnvironmentName } from '@env/env-name.enum';

export const environment = {
  production: false,
  environmentName: EnvironmentName.Development,
  graphqlWsUrl: 'wss://dev-api.and6.com/graphql',
  graphqlHttpUrl: 'https://dev-api.and6.com/graphql',
  apiUrl: 'https://dev-api.and6.com',
  mediaUrl: 'https://dev-i.and6.com',
  exteriorUrl: 'https://dev.and6.com',
  interiorUrl: 'https://dev.and6.com/my',
  cellarUrl: 'https://dev-cellar.and6.com',
  sentryDsn: "https://5bdf2e8cd90fc8e167a3acb5baa36ab8@o1185263.ingest.sentry.io/4506343269662720",
  canChangeAutoApprove: [134060, 134058, 134057],
};
