import { ClassProvider, ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { SentryReporterService } from '@libs/core/src';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private sentryReporter: SentryReporterService) {}

  handleError(error: Error): void {
    console.error(error)

    if (this.isChunkLoadError(error)) {
      // there is no need to send this error message
      // this.sentryReporter.reportChunkLoadError(error);
    } else if (this.isHttpError(error)) {
      this.sentryReporter.reportHttpError(error);
    } else {
      this.sentryReporter.reportClientSideError(error);
    }
  }

  private isHttpError(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
  }

  private isChunkLoadError(error: Error): boolean {
    const chunkFailedMessage = /Loading chunk \S+ failed/i;

    return chunkFailedMessage.test(error.message);
  }
}

export const AND6_GLOBAL_ERROR_HANDLER_PROVIDER: ClassProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
