<ng-container *ngrxLet="searchQuery$; let searchQuery">
  <div mat-dialog-title>
    <h4 class="text-ellipsis mr-10">Search Results: {{ searchQuery }}</h4>
    <a mat-dialog-close>
      <i class="sc sc-cancel text-gray"></i>
    </a>
  </div>

  <ng-scrollbar>
    <div mat-dialog-content class="searchbar-content mt-0 pt-0">
      <ng-container *ngIf="loading$ | async; else renderContent">
        <div class="d-flex justify-center align-center py-20">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>

      <ng-template #renderContent>
        <ng-container *ngrxLet="resultCount$; let count">
          <mat-tab-group
            *ngIf="count > 0; else emptyData"
            [@.disabled]="true"
            class="w-100"
          >
            <ng-container *ngFor="let item of data">
              <mat-tab [label]="item.type + 'S'" *ngIf="item.count">
                <lib-search-result-table
                  [opsAccounts]="opsAccounts$ | async"
                  [dataSource]="item.dataSource"
                  [type]="item.type"
                  [searchQuery]="searchQuery"
                  [displayedColumns]="item.displayColumns"
                ></lib-search-result-table>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </ng-container>

        <ng-template #emptyData>
          <p class="fs-14 text-gray text-center w-100 my-30">No Result !</p>
        </ng-template>
      </ng-template>
    </div>
  </ng-scrollbar>
</ng-container>
