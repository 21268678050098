<div class="title-header">
  <h3 class="text-primary">{{ title }} ({{ totalCount }})</h3>

  <button
    *ngIf="processBtn"
    mat-flat-button
    class="sm"
    color="primary"
    [loading]="processBtnState === 'LOADING'"
    [disabled]="processBtnState === 'DISABLED'"
    (click)="processTrigger()"
  >
    <i *ngIf="btnIcon" class="sc fs-20 mr-6" [class]="btnIcon"></i>
    {{ btnText || 'Process everything' }}
  </button>
</div>
