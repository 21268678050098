// Angular specific
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { mergeMap, Observable, of, race } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

// Third party
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

// Project specific
import { GraphqlWebsocketStatus } from '@libs/core/src/lib/types';
import { AuthService, GqlWsService } from '@libs/core/src/lib/services';
import { OpsAccountActions } from '@libs/core/src/lib/root-store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private authService: AuthService,
    private gqlWsService: GqlWsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.authToken$.pipe(
      take(1),
      mergeMap((authToken) => {
        if (!authToken) {
          this.authService.redirectUrl = state.url;
          this.authService.logout();
          return of(false);
        }

        return this.gqlWsService.connectionState$.pipe(
          filter(({ status }) => status === GraphqlWebsocketStatus.Init),
          take(1),
          mergeMap(({ error }) => {
            if (error) {
              this.authService.redirectUrl = state.url;
              this.authService.logout();
              return of(false);
            }

            return this.waitForOpsAccountsToLoad();
          })
        );
      })
    );
  }

  waitForOpsAccountsToLoad() {
    this.store.dispatch(OpsAccountActions.loadAll());

    return race(
      this.actions$.pipe(ofType(OpsAccountActions.loadAllSuccess)),
      this.actions$.pipe(ofType(OpsAccountActions.loadAllFailure))
    ).pipe(
      map((action) => {
        if (action.type === OpsAccountActions.loadAllSuccess.type) {
          return true;
        }

        this.authService.redirectUrl = this.router.url;
        this.authService.logout();
        return false;
      })
    );
  }
}
