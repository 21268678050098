import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
export type AuthenticateMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  username: Types.Scalars['String'];
  lifetime?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type AuthenticateMutation = {
  public?: Types.Maybe<{
    authenticate?: Types.Maybe<Pick<Types.AccountToken, 'token'>>;
  }>;
};

export const AuthenticateDocument = gql`
  mutation authenticate(
    $password: String!
    $username: String!
    $lifetime: Int
  ) {
    public {
      authenticate(
        password: $password
        username: $username
        lifetime: $lifetime
      ) {
        token
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGQL extends Apollo.Mutation<
  AuthenticateMutation,
  AuthenticateMutationVariables
> {
  document = AuthenticateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
