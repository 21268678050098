import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorResponse } from '@apollo/client/link/error';
import { Operation } from '@apollo/client/core';
import { GraphQLError } from 'graphql';

import { getOperationDefinition } from '@apollo/client/utilities';
import {
  captureMessage,
  Scope,
  User as SentryExpectedUser,
} from '@sentry/angular';
import { Extras } from '@sentry/types';
import { AuthTokenService } from '@libs/core/src';

@Injectable({
  providedIn: 'root',
})
export class SentryReporterService implements ISentryReporter {
  constructor(private authTokenService: AuthTokenService) {}

  reportGraphqlErrors({
    operation,
    graphQLErrors,
    networkError,
  }: ErrorResponse): void {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const scope = new Scope()
          .setTransactionName(error?.path?.join('.') || error.name)
          .setTag('kind', getOperationDefinition(operation.query).operation)
          .setExtras(createErrorExtras(error))
          .setExtras(createOperationExtras(operation))
          .setUser(this.getAuthUser())
          .addBreadcrumb({
            category: 'query-path',
            message: error?.path?.join(' > ') || error?.path?.toString() || '_',
            level: 'error',
          });

        captureMessage(SentryIssueScope.GraphQLError, scope);
      });
    }

    if (networkError) {
      const scope = new Scope()
        .setTransactionName(networkError?.name)
        .setTag('kind', getOperationDefinition(operation?.query)?.operation)
        .setExtras(createOperationExtras(operation))
        .setExtras(createErrorExtras(new GraphQLError(networkError.message)))
        .setUser(this.getAuthUser());

      captureMessage(SentryIssueScope.GraphQLNetworkError, scope);
    }
  }

  reportHttpError(httpError: HttpErrorResponse): void {
    const scope = new Scope()
      .setTransactionName(httpError.name)
      .setExtras({ ...httpError })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.HttpError, scope);
  }

  reportChunkLoadError(error: Error): void {
    const scope = new Scope()
      .setTransactionName(error.name)
      .setExtras({ message: error.message })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.ChunkLoadError, scope);
  }

  reportClientSideError(error: Error): void {
    const scope = new Scope()
      .setTransactionName(error.name)
      .setExtras({
        message: {
          name: error.name,
          message: error.message,
        },
      })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.ClientSideError, scope);
  }

  getAuthUser(): SentryExpectedUser {
    return (
      this.authTokenService.getDecodedToken() || {
        id: null,
        email: null,
        role: null,
      }
    );
  }
}

interface ISentryReporter {
  reportGraphqlErrors(errorResponse: ErrorResponse): void;

  reportHttpError(httpError: HttpErrorResponse): void;

  reportChunkLoadError(error: Error): void;

  reportClientSideError(error: Error): void;
}

export enum SentryIssueScope {
  GraphQLError = 'GraphQL Error',
  GraphQLNetworkError = 'GraphQL Network Error',
  HttpError = 'Http Error',
  ChunkLoadError = 'Chunk Load Error',
  ClientSideError = 'Client Side Error',
}

function createOperationExtras(operation: Operation): Extras {
  const operationTypeNode: 'query' | 'mutation' | 'subscription' =
    getOperationDefinition(operation.query).operation;

  return {
    [operationTypeNode]: {
      definitions: operation.query.definitions.reduce((acc, def) => {
        if (def?.['name']?.['value']) {
          acc[def?.['name']?.['value']] = JSON.stringify({
            kind: def?.['kind'],
            name: def?.['name'],
            operation: def?.['operation'] || '_',
          });
        }
        return acc;
      }, {}),
    },
    variables: JSON.stringify(operation.variables),
  };
}

function createErrorExtras(error: GraphQLError): Extras {
  return {
    error: {
      message: error.message,
      path: error.path,
    },
  };
}
