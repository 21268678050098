// Angular specific
import {
  animate,
  AnimationStyleMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export class LayoutAnimations {
  static defaultRippleAnimationConfig(
    animationName: string = 'defaultAnimation',
    ms: number = 250
  ) {
    return trigger(animationName, [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate(`${ms}ms ease-out`, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(`${ms}ms ease-in`, style({ opacity: 0 })),
      ]),
    ]);
  }

  static inContentAnimation(ms: number = 250) {
    return trigger('inContentAnimation', [
      transition(':enter', [
        style({ height: '*', opacity: 0 }),
        animate(`${ms}ms ease-in`, style({ opacity: 1, height: '*' })),
      ]),
    ]);
  }

  static outContentAnimation(ms: number = 250) {
    return trigger('outContentAnimation', [
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate(`${ms}ms ease-out`, style({ opacity: 0, height: 0 })),
      ]),
    ]);
  }

  static backdropToggle(ms: number = 300) {
    return trigger('backdropToggle', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(`${ms}ms`, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(`${ms}ms`, style({ opacity: 0 }))]),
    ]);
  }

  static expandCollapse(ms: number = 250, styles = {}) {
    return trigger('expandCollapse', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'close',
        style({
          height: '0px',
          ...styles,
        })
      ),
      transition('open <=> close', animate(`${ms}ms ease`)),
    ]);
  }
}
