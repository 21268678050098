import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
import * as ApolloCore from '@apollo/client/core';
export type EntitiesCountsForAdmninsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type EntitiesCountsForAdmninsQuery = {
  ops?: Types.Maybe<{
    pendingNewProfileCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingModifiedProfilesCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfilePhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingModifiedCompanyProfilesCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingCompanyProfilePhotosCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    storyPendingStoriesCount: Pick<Types.StoryPendingCountResponse, 'count'>;
    pendingAgeVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingOneHundredVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingProfileVideosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfileNaturalPhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingCommentsCount?: Types.Maybe<
      Pick<Types.PendingCommentsCountResponse, 'count'>
    >;
    pendingBlacklistedClientsCount?: Types.Maybe<
      Pick<Types.PendingBlacklistedClientsCountResponse, 'count'>
    >;
    supportTicketsByFilter?: Types.Maybe<
      Pick<Types.SupportTicketsByFilterResponse, 'count'>
    >;
    classifiedAdsByFilter?: Types.Maybe<
      Pick<Types.ClassifiedAdsFilterResponse, 'count'>
    >;
    findTop10CommentsForCellar: Pick<
      Types.Top10CommentsForCellarResponse,
      'count'
    >;
    supportProblemReportsByFilter?: Types.Maybe<
      Pick<Types.SupportProblemReportsByFilterResponse, 'count'>
    >;
    pendingPaymentCount: Pick<Types.PendingPaymentCountResponse, 'count'>;
    accountUpdateOpsRequests?: Types.Maybe<
      Array<Types.Maybe<Pick<Types.Account, 'id'>>>
    >;
    pendingCompanyCommentsCount?: Types.Maybe<
      Pick<Types.PendingCompanyCommentsCountResponse, 'count'>
    >;
    pendingNewCompanyProfileCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingBannersCount?: Types.Maybe<Pick<Types.BannerCountResponse, 'count'>>;
  }>;
};

export type EntitiesCountsForManagersQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type EntitiesCountsForManagersQuery = {
  ops?: Types.Maybe<{
    pendingNewProfileCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingModifiedProfilesCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfilePhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingModifiedCompanyProfilesCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingCompanyProfilePhotosCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    storyPendingStoriesCount: Pick<Types.StoryPendingCountResponse, 'count'>;
    pendingAgeVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingOneHundredVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingProfileVideosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfileNaturalPhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingCommentsCount?: Types.Maybe<
      Pick<Types.PendingCommentsCountResponse, 'count'>
    >;
    pendingBlacklistedClientsCount?: Types.Maybe<
      Pick<Types.PendingBlacklistedClientsCountResponse, 'count'>
    >;
    classifiedAdsByFilter?: Types.Maybe<
      Pick<Types.ClassifiedAdsFilterResponse, 'count'>
    >;
    pendingCompanyCommentsCount?: Types.Maybe<
      Pick<Types.PendingCompanyCommentsCountResponse, 'count'>
    >;
    pendingNewCompanyProfileCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingBannersCount?: Types.Maybe<Pick<Types.BannerCountResponse, 'count'>>;
    findTop10CommentsForCellar: Pick<
      Types.Top10CommentsForCellarResponse,
      'count'
    >;
    supportTicketsByFilter?: Types.Maybe<
      Pick<Types.SupportTicketsByFilterResponse, 'count'>
    >;
    supportProblemReportsByFilter?: Types.Maybe<
      Pick<Types.SupportProblemReportsByFilterResponse, 'count'>
    >;
    accountUpdateOpsRequests?: Types.Maybe<
      Array<Types.Maybe<Pick<Types.Account, 'id'>>>
    >;
  }>;
};

export type EntitiesCountsForSalesQueryVariables = Types.Exact<{
  accountId: Types.Scalars['Int'];
}>;

export type EntitiesCountsForSalesQuery = {
  ops?: Types.Maybe<{
    pendingNewProfileCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingNewCompanyProfileCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingAgeVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingOneHundredVerificationsCount?: Types.Maybe<
      Pick<Types.PendingVerificationCountResponse, 'count'>
    >;
    pendingModifiedProfilesCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfilePhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingModifiedCompanyProfilesCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    pendingCompanyProfilePhotosCount?: Types.Maybe<
      Pick<Types.CompanyPendingCountResponse, 'count'>
    >;
    storyPendingStoriesCount: Pick<Types.StoryPendingCountResponse, 'count'>;
    pendingProfileVideosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    pendingProfileNaturalPhotosCount?: Types.Maybe<
      Pick<Types.PendingCountResponse, 'count'>
    >;
    supportTicketsByFilter?: Types.Maybe<
      Pick<Types.SupportTicketsByFilterResponse, 'count'>
    >;
    accountUpdateOpsRequests?: Types.Maybe<
      Array<Types.Maybe<Pick<Types.Account, 'id'>>>
    >;
  }>;
};

export const EntitiesCountsForAdmninsDocument = gql`
  query entitiesCountsForAdmnins {
    ops {
      pendingNewProfileCount {
        count
      }
      pendingModifiedProfilesCount {
        count
      }
      pendingProfilePhotosCount {
        count
      }
      pendingModifiedCompanyProfilesCount {
        count
      }
      pendingCompanyProfilePhotosCount {
        count
      }
      storyPendingStoriesCount {
        count
      }
      pendingAgeVerificationsCount {
        count
      }
      pendingOneHundredVerificationsCount {
        count
      }
      pendingProfileVideosCount {
        count
      }
      pendingProfileNaturalPhotosCount {
        count
      }
      pendingCommentsCount {
        count
      }
      pendingBlacklistedClientsCount {
        count
      }
      supportTicketsByFilter(filter: { status: PENDING, limit: 0, offset: 0 }) {
        count
      }
      classifiedAdsByFilter(filter: { status: PENDING }) {
        count
      }
      findTop10CommentsForCellar(comment_status: PENDING) {
        count
      }
      supportProblemReportsByFilter(
        filter: { status: PENDING, limit: 0, offset: 0 }
      ) {
        count
      }
      pendingPaymentCount {
        count
      }
      accountUpdateOpsRequests {
        id
      }
      pendingCompanyCommentsCount {
        count
      }
      pendingNewCompanyProfileCount {
        count
      }
      pendingBannersCount {
        count
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EntitiesCountsForAdmninsGQL extends Apollo.Query<
  EntitiesCountsForAdmninsQuery,
  EntitiesCountsForAdmninsQueryVariables
> {
  document = EntitiesCountsForAdmninsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EntitiesCountsForManagersDocument = gql`
  query entitiesCountsForManagers {
    ops {
      pendingNewProfileCount {
        count
      }
      pendingModifiedProfilesCount {
        count
      }
      pendingProfilePhotosCount {
        count
      }
      pendingModifiedCompanyProfilesCount {
        count
      }
      pendingCompanyProfilePhotosCount {
        count
      }
      storyPendingStoriesCount {
        count
      }
      pendingAgeVerificationsCount {
        count
      }
      pendingOneHundredVerificationsCount {
        count
      }
      pendingProfileVideosCount {
        count
      }
      pendingProfileNaturalPhotosCount {
        count
      }
      pendingCommentsCount {
        count
      }
      pendingBlacklistedClientsCount {
        count
      }
      classifiedAdsByFilter(filter: { status: PENDING }) {
        count
      }
      pendingCompanyCommentsCount {
        count
      }
      pendingNewCompanyProfileCount {
        count
      }
      pendingBannersCount {
        count
      }
      findTop10CommentsForCellar(comment_status: PENDING) {
        count
      }
      supportTicketsByFilter(filter: { status: PENDING, limit: 0, offset: 0 }) {
        count
      }
      supportProblemReportsByFilter(
        filter: { status: PENDING, limit: 0, offset: 0 }
      ) {
        count
      }
      accountUpdateOpsRequests {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EntitiesCountsForManagersGQL extends Apollo.Query<
  EntitiesCountsForManagersQuery,
  EntitiesCountsForManagersQueryVariables
> {
  document = EntitiesCountsForManagersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EntitiesCountsForSalesDocument = gql`
  query entitiesCountsForSales($accountId: Int!) {
    ops {
      pendingNewProfileCount {
        count
      }
      pendingNewCompanyProfileCount {
        count
      }
      pendingAgeVerificationsCount {
        count
      }
      pendingOneHundredVerificationsCount {
        count
      }
      pendingModifiedProfilesCount {
        count
      }
      pendingProfilePhotosCount {
        count
      }
      pendingModifiedCompanyProfilesCount {
        count
      }
      pendingCompanyProfilePhotosCount {
        count
      }
      storyPendingStoriesCount {
        count
      }
      pendingProfileVideosCount {
        count
      }
      pendingProfileNaturalPhotosCount {
        count
      }
      supportTicketsByFilter(
        filter: {
          status: PENDING
          limit: 0
          offset: 0
          ops_account_id: $accountId
        }
      ) {
        count
      }
      accountUpdateOpsRequests {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EntitiesCountsForSalesGQL extends Apollo.Query<
  EntitiesCountsForSalesQuery,
  EntitiesCountsForSalesQueryVariables
> {
  document = EntitiesCountsForSalesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
  extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
  extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
  extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

interface SubscriptionOptionsAlone<V>
  extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class EntitiesCountsSdk {
  constructor(
    private entitiesCountsForAdmninsGql: EntitiesCountsForAdmninsGQL,
    private entitiesCountsForManagersGql: EntitiesCountsForManagersGQL,
    private entitiesCountsForSalesGql: EntitiesCountsForSalesGQL
  ) {}

  entitiesCountsForAdmnins(
    variables?: EntitiesCountsForAdmninsQueryVariables,
    options?: QueryOptionsAlone<EntitiesCountsForAdmninsQueryVariables>
  ) {
    return this.entitiesCountsForAdmninsGql.fetch(variables, options);
  }

  entitiesCountsForAdmninsWatch(
    variables?: EntitiesCountsForAdmninsQueryVariables,
    options?: WatchQueryOptionsAlone<EntitiesCountsForAdmninsQueryVariables>
  ) {
    return this.entitiesCountsForAdmninsGql.watch(variables, options);
  }

  entitiesCountsForManagers(
    variables?: EntitiesCountsForManagersQueryVariables,
    options?: QueryOptionsAlone<EntitiesCountsForManagersQueryVariables>
  ) {
    return this.entitiesCountsForManagersGql.fetch(variables, options);
  }

  entitiesCountsForManagersWatch(
    variables?: EntitiesCountsForManagersQueryVariables,
    options?: WatchQueryOptionsAlone<EntitiesCountsForManagersQueryVariables>
  ) {
    return this.entitiesCountsForManagersGql.watch(variables, options);
  }

  entitiesCountsForSales(
    variables: EntitiesCountsForSalesQueryVariables,
    options?: QueryOptionsAlone<EntitiesCountsForSalesQueryVariables>
  ) {
    return this.entitiesCountsForSalesGql.fetch(variables, options);
  }

  entitiesCountsForSalesWatch(
    variables: EntitiesCountsForSalesQueryVariables,
    options?: WatchQueryOptionsAlone<EntitiesCountsForSalesQueryVariables>
  ) {
    return this.entitiesCountsForSalesGql.watch(variables, options);
  }
}
