import { InjectionToken } from '@angular/core';

const AND6_DEFAULT_TIMEZONE: string = 'Europe/Zurich';

export const AND6_TIMEZONE = new InjectionToken<string>(
  'AND6_DEFAULT_TIMEZONE',
  {
    providedIn: 'root',
    factory: () => AND6_DEFAULT_TIMEZONE,
  }
);
