// Angular specific
import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EMPTY, of, timer } from 'rxjs';

// Third party
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';

// Core specific
import { AccountService } from '../../services/account.service';
import { State } from '../reducers';
import { OpsAccountActions } from '../actions';
import { ScNotifierService } from '@sceon/notifier';

@Injectable()
export class OpsAccountEffects {
  loadOpsAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpsAccountActions.loadAll),
      mergeMap(() =>
        this.accountService.getOpsAccounts().pipe(
          map((accounts) => OpsAccountActions.loadAllSuccess({ accounts })),
          catchError((error: Error) => {
            this.scNotifier.error({
              title: 'Error',
              text: error.message,
            });

            return of(OpsAccountActions.loadAllFailure({ error }));
          })
        )
      )
    )
  );

  updateLastActiveDateByTimeInterval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpsAccountActions.updateLastActiveDate),
        switchMap(() =>
          // 5 minutes
          timer(0, 5 * 60 * 1000).pipe(
            startWith(),
            switchMap(() =>
              this.accountService.updateLastActiveDate().pipe(
                tap(() => {}),
                catchError((error: Error) => {
                  return EMPTY;
                })
              )
            ),
            takeUntil(
              // logout action stream$
              this.actions$.pipe(
                ofType(routerNavigatedAction),
                filter(({ payload }) =>
                  payload.routerState.url.startsWith('/auth')
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  updateSkypeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpsAccountActions.updateSkypeStatus),
      mergeMap(({ account_id, status }) =>
        this.accountService.updateOpsSkypeStatus(account_id, status).pipe(
          map(({ result }) => {
            return OpsAccountActions.updateSkypeStatusSuccess({
              account_id,
              status,
            });
          }),
          catchError((error: Error) => {
            this.scNotifier.error({
              title: 'Error',
              text: error.message,
            });
            return of(OpsAccountActions.updateSkypeStatusFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private accountService: AccountService,
    private scNotifier: ScNotifierService
  ) {}
}
