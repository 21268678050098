import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { SharedConfigActions } from '@core/root-store';
import { catchError, map, mergeMapTo } from 'rxjs/operators';
import { PaymentService } from '@libs/core/src';
import { of } from 'rxjs';

@Injectable()
export class SharedConfigsEffects {
  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedConfigActions.loadPaymentMethods),
      mergeMapTo(
        this.paymentService.getPaymentMethods().pipe(
          map((paymentMethods) =>
            SharedConfigActions.loadPaymentMethodsSuccess({
              paymentMethods,
            })
          ),
          catchError((error) =>
            of(
              SharedConfigActions.loadPaymentMethodsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private paymentService: PaymentService
  ) {}
}
