import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
export type PaymentMethodsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type PaymentMethodsQuery = {
  ops?: Types.Maybe<{
    paymentMethods: Array<
      Types.Maybe<Pick<Types.PaymentMethod, 'name' | 'display_name'>>
    >;
  }>;
};

export const PaymentMethodsDocument = gql`
  query paymentMethods {
    ops {
      paymentMethods {
        name
        display_name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsGQL extends Apollo.Query<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
> {
  document = PaymentMethodsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
