import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    text: string | number,
    query: string,
    highlightData: { key: string; data: Record<string, any>[] }
  ): SafeHtml {
    if (highlightData.data && highlightData.data[highlightData.key]?.length) {
      return highlightData.data[highlightData.key][0];
    }

    let search: string =
      typeof text === 'number' ? parseInt(query).toString() : query.trim();

    if (search && text) {
      let pattern = search.replace(
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        '\\$&'
      );
      pattern = pattern
        .split(' ')
        .filter((t) => {
          return t.length > 0;
        })
        .join('|');
      const regex = new RegExp(pattern, 'i');
      return this.sanitizer.bypassSecurityTrustHtml(
        String(text).replace(regex, (match) => `<em>${match}</em>`)
      );
    } else {
      return String(text);
    }
  }
}
