import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';

import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

import { LayoutService } from '../../layout/layout.service';
import { SearchbarStore } from './searchbar.store';
import { SearchbarResultDialogComponent } from './searchbar-result-dialog/searchbar-result-dialog.component';

@Component({
  selector: 'lib-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  providers: [SearchbarStore],
})
export class SearchbarComponent implements OnInit, OnChanges {
  @ViewChild(MatInput) matInput: MatInput;
  @HostBinding('class.opened') @Input() opened: boolean;
  @Output() close = new EventEmitter();

  searchControl = new UntypedFormControl('');

  constructor(
    private searchbarStore: SearchbarStore,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.searchbarStore.setDialogCmp(SearchbarResultDialogComponent);

    this.layoutService.isTablet$
      .pipe(skip(1))
      .subscribe(() => this.close.emit());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.opened) {
      this.matInput.focus();
    }
  }

  closeSearchBar() {
    this.close.emit();
  }

  search() {
    if (this.searchControl.value) {
      this.searchbarStore.updateSearchQuery(this.searchControl.value);

      this.closeSearchBar();
    }
  }
}
