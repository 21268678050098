import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sc-not-result',
  template: ` <div [ngClass]="class">
    <img [src]="icon" alt="" />
    <p>{{ text }}</p>
  </div>`,
  styleUrls: ['./not-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScNotResultComponent {
  @Input() text: string = 'Not Result!';
  @Input() icon: string = 'assets/images/empty-icon.svg';
  @Input() class: string;
}
