// Angular specific
import {
  ChangeDetectionStrategy,
  ElementRef,
  TemplateRef,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
} from '@angular/core';

// Project specific
import { AccountSkypeStatus, OpsAccount, OpsRole } from '@gql-types';
import { ScMapperPipeFn } from '@shared/pipes';
import { EnumHelper } from '@shared/utils/enum-helper';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent {
  @Input() showSidenav: boolean;

  @Input() authAccount: OpsAccount;

  @Output() menuToggled = new EventEmitter<boolean>();

  @Output() logout = new EventEmitter<void>();

  @Output() skypeStatusChange = new EventEmitter<AccountSkypeStatus>();

  @Output() passwordChange = new EventEmitter<void>();

  @HostBinding('class.primary-header') get primary() {
    return [OpsRole.Manager].includes(this.authAccount?.role);
  }

  @HostBinding('class.accent-header') get accent() {
    return [OpsRole.Admin].includes(this.authAccount?.role);
  }

  mobileSearchBarOpened = false;

  skypeStatuses = EnumHelper.getValues<AccountSkypeStatus>(AccountSkypeStatus);

  accountRole = { ...OpsRole };
  skypeStatusEntities = { ...AccountSkypeStatus };

  skypeStatusClassToggleFn: ScMapperPipeFn<AccountSkypeStatus, string> = (
    status
  ) => {
    switch (status) {
      case AccountSkypeStatus.Away:
        return 'sc-skype-away text-warning';
      case AccountSkypeStatus.DoNotDisturb:
        return 'sc-skype-dont-disturb text-danger';
      case AccountSkypeStatus.Offline:
        return 'sc-skype-offline text-secondary';
      case AccountSkypeStatus.Online:
        return 'sc-skype-online text-success';
      default:
        return 'sc-skype-offline text-secondary';
    }
  };

  constructor(private elRef: ElementRef) {}

  toggle() {
    this.menuToggled.emit();
  }

  toggleSearchBar($event: boolean | null = null) {
    if ($event === null) {
      this.mobileSearchBarOpened = !this.mobileSearchBarOpened;
      this.menuToggled.emit(false);
      (
        this.elRef.nativeElement.parentNode.parentNode as HTMLDivElement
      ).classList.toggle('overflow-hidden-sm');
    } else {
      this.mobileSearchBarOpened = $event;
      (
        this.elRef.nativeElement.parentNode.parentNode as HTMLDivElement
      ).classList.remove('overflow-hidden-sm');
    }
  }

  onLogout() {
    this.logout.emit();
  }

  onSkypeStatusChange(status: AccountSkypeStatus) {
    this.skypeStatusChange.emit(status);
  }

  onPasswordChange() {
    this.passwordChange.emit();
  }
}
