import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Directive({
  selector: '[matDatepicker]',
})
export class DatepickerDirective implements AfterViewInit {
  @Input() matDatepicker: MatDatepicker<any>;

  @HostListener('click', ['$event'])
  onClick() {
    this.elRef.nativeElement.blur();
    this.matDatepicker.open();
  }

  constructor(
    private elRef: ElementRef<HTMLInputElement>,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.renderer.setAttribute(this.elRef.nativeElement, 'readonly', '');
  }
}
