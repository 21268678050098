import { Injectable } from '@angular/core';
import { EntitiesCountsSdk } from './_graphql/entities-counts';
import { catchError, map } from 'rxjs/operators';
import { PendingEntitiesCounts, PendingEntityIdentifier } from '../types/index';
import { of } from 'rxjs';

interface EntityCountQuery {
  count?: number;
  length?: number;
}
type EntityCountsQueries = { [key: string]: EntityCountQuery };

@Injectable({
  providedIn: 'root',
})
export class PendingEntitiesService {
  constructor(private entitiesCountsSdk: EntitiesCountsSdk) {}

  getCountsForAdmins() {
    return this.entitiesCountsSdk.entitiesCountsForAdmnins().pipe(
      map((payload) =>
        this.queriesToEntitiesMap(payload.data.ops as EntityCountsQueries)
      ),
      catchError((err) => of({}))
    );
  }

  getCountsForSales(accountId) {
    return this.entitiesCountsSdk.entitiesCountsForSales({ accountId }).pipe(
      map((payload) =>
        this.queriesToEntitiesMap(payload.data.ops as EntityCountsQueries)
      ),
      catchError((err) => of({}))
    );
  }

  getCountsForManagers() {
    return this.entitiesCountsSdk.entitiesCountsForManagers().pipe(
      map((payload) =>
        this.queriesToEntitiesMap(payload.data.ops as EntityCountsQueries)
      ),
      catchError((err) => of({}))
    );
  }

  private queriesToEntitiesMap(
    data: EntityCountsQueries
  ): PendingEntitiesCounts {
    return {
      [PendingEntityIdentifier.AgeVerifications]:
        data.pendingAgeVerificationsCount
          ? data.pendingAgeVerificationsCount.count
          : null,
      [PendingEntityIdentifier.ClientBlacklist]:
        data.pendingBlacklistedClientsCount
          ? data.pendingBlacklistedClientsCount.count
          : null,
      [PendingEntityIdentifier.Comments]: data.pendingCommentsCount
        ? data.pendingCommentsCount.count
        : null,
      [PendingEntityIdentifier.Issues]: data.supportTicketsByFilter
        ? data.supportTicketsByFilter.count
        : null,
      [PendingEntityIdentifier.ClassifiedAds]: data.classifiedAdsByFilter
        ? data.classifiedAdsByFilter.count
        : null,
      [PendingEntityIdentifier.Top10Comments]: data.findTop10CommentsForCellar
        ? data.findTop10CommentsForCellar.count
        : null,
      [PendingEntityIdentifier.NaturalPhotos]:
        data.pendingProfileNaturalPhotosCount
          ? data.pendingProfileNaturalPhotosCount.count
          : null,
      [PendingEntityIdentifier.NewRegisteredProfiles]:
        data.pendingNewProfileCount ? data.pendingNewProfileCount.count : null,
      [PendingEntityIdentifier.OneHundredVerifications]:
        data.pendingOneHundredVerificationsCount
          ? data.pendingOneHundredVerificationsCount.count
          : null,
      [PendingEntityIdentifier.Stories]: data.storyPendingStoriesCount
        ? data.storyPendingStoriesCount.count
        : null,
      [PendingEntityIdentifier.PendingPayments]: data.pendingPaymentCount
        ? data.pendingPaymentCount.count
        : null,
      [PendingEntityIdentifier.Photos]: data.pendingProfilePhotosCount
        ? data.pendingProfilePhotosCount.count
        : null,
      [PendingEntityIdentifier.AgencyPhotos]: data.pendingCompanyProfilePhotosCount
        ? data.pendingCompanyProfilePhotosCount.count
        : null,
      [PendingEntityIdentifier.AgencyProfileUpdates]: data.pendingModifiedCompanyProfilesCount
        ? data.pendingModifiedCompanyProfilesCount.count
        : null,
      [PendingEntityIdentifier.ProfileProblemReports]:
        data.supportProblemReportsByFilter
          ? data.supportProblemReportsByFilter.count
          : null,
      [PendingEntityIdentifier.ProfileUpdates]:
        data.pendingModifiedProfilesCount
          ? data.pendingModifiedProfilesCount.count
          : null,
      [PendingEntityIdentifier.Videos]: data.pendingProfileVideosCount
        ? data.pendingProfileVideosCount.count
        : null,
      [PendingEntityIdentifier.SalesChangeRequests]:
        data.accountUpdateOpsRequests
          ? data.accountUpdateOpsRequests.count
          : null,
      [PendingEntityIdentifier.AgencyComments]: data.pendingCompanyCommentsCount
        ? data.pendingCompanyCommentsCount.count
        : null,
      [PendingEntityIdentifier.Banners]: data.pendingBannersCount
        ? data.pendingBannersCount.count
        : null,
      [PendingEntityIdentifier.NewRegisteredAgencies]:
        data.pendingNewCompanyProfileCount
          ? data.pendingNewCompanyProfileCount.length
          : null,
    } as PendingEntitiesCounts;
  }
}
