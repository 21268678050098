import { TitleEffects } from './title.effects';
import { OpsAccountEffects } from './ops-account.effects';
import { SharedConfigsEffects } from './shared-configs.effects';
import { LayoutEffects } from './layout.effects';
import { PubSubEffects } from './pub-sub.effects';
import { NavigationEffects } from './navigation.effects';

export const rootEffects = [
  TitleEffects,
  LayoutEffects,
  NavigationEffects,
  OpsAccountEffects,
  SharedConfigsEffects,
  PubSubEffects,
];
