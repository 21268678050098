<div class="container-fluid full-width-md">
  <div class="container">
    <div class="header">
      <div class="left-side d-flex align-center">
        <i
          class="sc menu-toggle text-info"
          [ngClass]="showSidenav ? 'sc-cancel' : ' sc-menu'"
          (click)="toggle()"
        ></i>
        <lib-searchbar
          class="searchbar"
          [opened]="mobileSearchBarOpened"
          (close)="toggleSearchBar(false)"
        >
          <i
            (click)="menuToggled.emit()"
            class="sc sc-menu desktop-toggler"
            toggleSideMenu
          ></i>
        </lib-searchbar>

        <span
          digitalClock
          class="digital-clock"
          matTooltip="Switzerland time"
        ></span>
      </div>

      <div class="center align-center">
        <a [routerLink]="'./'" class="d-flex align-center">
          <img src="assets/images/and6-logo-small-dark.png" alt="" />
        </a>
      </div>

      <div class="right-side d-flex align-center">
        <div
          *ngIf="
            authAccount.role === accountRole.Sales &&
            authAccount?.ops_contact_data?.skype
          "
          class="mr-30"
        >
          <a
            [matMenuTriggerFor]="skypeMenu"
            class="fs-16 text-primary text-medium user-menu"
            [matTooltip]="authAccount.ops_contact_data.skype.status | lowercase"
          >
            <i
              class="sc fs-20 {{
                authAccount.ops_contact_data.skype.status
                  | mapper: skypeStatusClassToggleFn
              }}"
            ></i>
          </a>
          <mat-menu
            #skypeMenu="matMenu"
            xPosition="before"
            class="user-menu-list"
          >
            <button
              mat-menu-item
              *ngFor="let status of skypeStatuses"
              [disabled]="authAccount.ops_contact_data.skype.status === status"
              (click)="onSkypeStatusChange(status)"
            >
              {{ status }}
            </button>
          </mat-menu>
        </div>
        <i
          class="sc sc-search text-info search"
          (click)="toggleSearchBar()"
          matSuffix
        ></i>

        <a
          #menu="matMenuTrigger"
          [matMenuTriggerFor]="matMenu"
          class="fs-16 text-primary text-medium user-menu"
        >
          {{ authAccount.showname }} ({{ authAccount.role | lowercase }})
          <i
            class="sc text-gray"
            [ngClass]="menu.menuOpen ? 'sc-arrow-up' : 'sc-arrow-down '"
          ></i>
        </a>

        <mat-menu #matMenu="matMenu" xPosition="before" class="user-menu-list">
          <button mat-menu-item (click)="onPasswordChange()">
            Change Password
          </button>
          <a mat-menu-item routerLink="/ops-tools/vacations">Vacations</a>
          <a mat-menu-item routerLink="/ops-tools/private-messages">
            Private Messages
          </a>
          <button mat-menu-item (click)="onLogout()">Logout</button>
        </mat-menu>
      </div>
    </div>

    <div
      class="backdrop"
      *ngIf="mobileSearchBarOpened"
      (click)="toggleSearchBar()"
    ></div>
  </div>
</div>
