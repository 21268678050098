import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { UpdateOpsPasswordService } from './update-ops-password.service';
import { UpdateOpsPasswordDialogComponent } from './update-ops-password-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ScDirectivesModule } from '@shared/directives';

@NgModule({
  declarations: [UpdateOpsPasswordDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    ScDirectivesModule,
  ],
  providers: [UpdateOpsPasswordService],
})
export class UpdateOpsPasswordModule {}
