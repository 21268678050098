import { OpsRole } from '@gql-types';
import { PendingEntityIdentifier } from '../types';

export namespace Menu {
  export enum ItemIdentifier {
    UserInfo = 'USER_INFO',
    Dashboard = 'DASHBOARD',
    Search = 'SEARCH',
    ProfileSearch = 'PROFILE_SEARCH',
    CompanyProfileSearch = 'COMPANY_PROFILE_SEARCH',
    MemberProfileSearch = 'MEMBER_PROFILE_SEARCH',
    HundredVerification = 'HUNDRED_VERIFICATION',
    PersonalUrl = 'PERSONAL_URL',
    AgeVerification = 'AGE_VERIFICATION',
    NotVerifiedAccounts = 'NOT_VERIFIED_ACCOUNTS',
    BannedAccounts = 'BANNED_ACCOUNTS',
    Ticket = 'TICKET',
    AIModeration = 'AI_MODERATION',
    Dictionary = 'DICTIONARY',
    Support = 'SUPPORT',
    Newsletter = 'NEWSLETTER',
    Discount = 'DISCOUNT',
    ClassifiedAds = 'CLASSIFIED_ADS',
    SmsAndEmail = 'SMS_AND_EMAIL',
    ClientBlacklist = 'CLIENT_BLACKLIST',
    Logout = 'Logout',
    System = 'SYSTEM',
    Template = 'TEMPLATE',
    Seo = 'SEO',
    Billing = 'BILLING',
    IpCookieDublicities = 'IPCOOKIEDUBLLICITIES',
    Banners = 'BANNERS',
    Chat = 'CHAT',
    StatusMessages = 'STATUS_MESSAGES',
  }

  export interface Item {
    id?: ItemIdentifier;
    path?: string;
    title?: string;
    icon?: string;
    href?: string;
    roles?: OpsRole[];
    subMenu?: ReadonlyArray<Partial<Item>>;
    badgeIdentifier?: PendingEntityIdentifier;
    queryParams?: { [key: string]: unknown };
    absolutePath?: boolean;
  }
}
