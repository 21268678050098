import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentName } from '@env/env-name.enum';

export const IS_DEVELOPMENT = new InjectionToken<boolean>('IS_DEVELOPMENT', {
  providedIn: 'root',
  factory: () => {
    return environment.environmentName === EnvironmentName.Development;
  },
});
