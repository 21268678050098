import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
export type UpdateLastActiveDateMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type UpdateLastActiveDateMutation = {
  ops?: Types.Maybe<{
    updateLastActiveDate?: Types.Maybe<Pick<Types.MutationResponse, 'result'>>;
  }>;
};

export const UpdateLastActiveDateDocument = gql`
  mutation updateLastActiveDate {
    ops {
      updateLastActiveDate {
        result
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateLastActiveDateGQL extends Apollo.Mutation<
  UpdateLastActiveDateMutation,
  UpdateLastActiveDateMutationVariables
> {
  document = UpdateLastActiveDateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
