import { Injectable } from '@angular/core';
import { exhaustMap, filter, map } from 'rxjs/operators';

import { createEffect, EffectNotification, OnRunEffects } from '@ngrx/effects';
import { createAction, props, select, Store } from '@ngrx/store';
import { Apollo, gql } from '@sceon/apollo-connector';

import { graphqlWebsocketQuery, State } from '../reducers';
import { Subscription } from '@gql-types';
import { Observable } from 'rxjs';
import { GraphqlWebsocketStatus } from '@core/types';
import selectGraphqlWebsocketState = graphqlWebsocketQuery.selectGraphqlWebsocketState;

// Namespace for subscription action types
export const PUB_SUB_ACTIONS_NAMESPACE = '[PubSub Event]';
export type PubSubscriptionMap = Omit<Subscription, 'my' | 'ops' | 'public'>;

// Create action by subscription name, and infer payload type from PubSubscriptionMap
const createPubSubAction = <
  T extends keyof PubSubscriptionMap,
  P extends PubSubscriptionMap[T]
>(
  eventType: T,
  payload: P
) =>
  createAction(
    `${PUB_SUB_ACTIONS_NAMESPACE} ${eventType}`,
    props<{ payload: P }>()
  )({ payload });

@Injectable()
export class PubSubEffects implements OnRunEffects {
  constructor(private apollo: Apollo, private store: Store<State>) {}

  mediaVideoProgress$ = createEffect(() =>
    this.apollo
      .subscribe<Pick<PubSubscriptionMap, 'mediaVideoProgressForWS'>>(
        {
          query: gql`
            subscription mediaVideoProgress {
              mediaVideoProgressForWS {
                id
                progress
              }
            }
          `,
        },
        { useZone: false }
      )
      .pipe(
        map(({ data }) =>
          createPubSubAction('mediaVideoProgressForWS', data.mediaVideoProgressForWS)
        )
      )
  );

  mediaVideoProcessFinished$ = createEffect(() =>
    this.apollo
      .subscribe<Pick<PubSubscriptionMap, 'mediaVideoProcessFinishedForWS'>>(
        {
          query: gql`
            subscription mediaVideoProcessFinished {
              mediaVideoProcessFinishedForWS {
                id
              }
            }
          `,
        },
        { useZone: false }
      )
      .pipe(
        map(({ data }) =>
          createPubSubAction(
            'mediaVideoProcessFinishedForWS',
            data.mediaVideoProcessFinishedForWS
          )
        )
      )
  );

  mediaVideoProcessError$ = createEffect(() =>
    this.apollo
      .subscribe<Pick<PubSubscriptionMap, 'mediaVideoProcessErrorForWS'>>(
        {
          query: gql`
            subscription mediaVideoProcessError {
              mediaVideoProcessErrorForWS {
                id
              }
            }
          `,
        },
        { useZone: false }
      )
      .pipe(
        map(({ data }) =>
          createPubSubAction(
            'mediaVideoProcessErrorForWS',
            data.mediaVideoProcessErrorForWS
          )
        )
      )
  );

  moverProfileMovedFromForRole$ = createEffect(() =>
    this.apollo
      .subscribe<Pick<PubSubscriptionMap, 'moverProfileMovedFromForRoleForWS'>>(
        {
          query: gql`
              subscription moverProfileMovedFromForRole {
                  moverProfileMovedFromForRoleForWS {
                      profile_reference_id
                      to_account_id
                      from_account_id
                  }
              }
          `,
        },
        { useZone: false }
      )
      .pipe(
        map(({ data }) =>
          createPubSubAction(
            'moverProfileMovedFromForRoleForWS',
            data.moverProfileMovedFromForRoleForWS
          )
        )
      )
  );

  connectionState$ = this.store.pipe(select(selectGraphqlWebsocketState));

  ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    return this.connectionState$.pipe(
      filter(
        ({ status, error }) => status === GraphqlWebsocketStatus.Init && !error
      ),
      exhaustMap(() => resolvedEffects$)
    );
  }
}
