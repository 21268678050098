import { Route } from '@angular/router';
import { OpsRole } from '@gql-types';

export interface CellarRoute extends Route {
  data?: {
    title: string;
    roles?: { whiteList?: OpsRole[]; blackList?: OpsRole[] };
  };
  children?: CellarRoute[];
}

export enum GraphqlWebsocketStatus {
  Init = 'WEBSOCKET_INIT_(connection_ack)',
  Connected = 'WEBSOCKET_CONNECTED',
  Reconnected = 'WEBSOCKET_RECONNECTED',
  Disconnected = 'WEBSOCKET_DISCONNECTED',
  Error = 'WEBSOCKET_ERROR',
}

export enum PendingEntityIdentifier {
  Photos = 'PHOTOS',
  AgencyPhotos = 'AGENCY_PHOTOS',
  Videos = 'VIDEOS',
  Stories = 'STORIES',
  Issues = 'ISSUES',
  Comments = 'COMMENTS',
  AgencyComments = 'AGENCY_COMMENTS',
  ClassifiedAds = 'CLASSIFIED_ADS',
  Top10Comments = 'TOP_10_COMMENTS',
  NaturalPhotos = 'NATURAL_PHOTOS',
  ProfileUpdates = 'PROFILE_UPDATES',
  AgencyProfileUpdates = 'AGENCY_PROFILE_UPDATES',
  PendingPayments = 'PENDING_PAYMENTS',
  ClientBlacklist = 'CLIENT_BLACKLIST',
  Banners = 'BANNERS',
  AgeVerifications = 'AGE_VERIFICATIONS',
  ProfileProblemReports = 'PROFILE_PROBLEM_REPORTS',
  NewRegisteredProfiles = 'NEW_REGISTERED_PROFILES',
  NewRegisteredAgencies = 'NEW_REGISTERED_AGENCIES',
  OneHundredVerifications = 'ONE_HUNDRED_VERIFICATIONS',
  SalesChangeRequests = 'SALES_CHANGE_REQUEST',
}

export type PendingEntitiesCounts = {
  [key in PendingEntityIdentifier]?: number;
};
