// Angular specific
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Module specific
import { ScAspectRatioDirective } from './aspect-ratio.directive';
import { ScLoaderDirective } from './sc-loader.directive';
import { ButtonLoadingDirective } from './button-loading.directive';
import { LazyImageDirective } from './lazy-image.directive';
import { DigitalClockDirective } from './digital-clock.directive';
import { HasPermissionDirective } from './has-permission.directive';
import { MatMenuHoverDirective } from './mat-menu-hover.directive';
import { PopoverDirective } from '@shared/directives/popover.directive';
import { DatepickerDirective } from './datepicker.directive';

@NgModule({
  declarations: [
    ScAspectRatioDirective,
    ScLoaderDirective,
    ButtonLoadingDirective,
    LazyImageDirective,
    DigitalClockDirective,
    HasPermissionDirective,
    MatMenuHoverDirective,
    PopoverDirective,
    DatepickerDirective,
  ],
  imports: [CommonModule],
  exports: [
    ScAspectRatioDirective,
    ScLoaderDirective,
    ButtonLoadingDirective,
    LazyImageDirective,
    DigitalClockDirective,
    HasPermissionDirective,
    MatMenuHoverDirective,
    PopoverDirective,
    DatepickerDirective,
  ],
  providers: [],
})
export class ScDirectivesModule {}
