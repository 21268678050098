<div mat-dialog-title>
  <h4>Update Password</h4>
  <a mat-dialog-close>
    <i class="sc sc-cancel text-gray"></i>
  </a>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" *ngIf="form">
    <!-- Key, Category -->
    <div class="key-category-container">
      <mat-form-field class="key">
        <mat-label>Current Password</mat-label>
        <input matInput type="password" formControlName="password" required />
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        class="mb-20 key text-uppercase"
        (click)="generatePass()"
      >
        generate
      </button>

      <mat-form-field class="key password-input">
        <mat-label>New password</mat-label>
        <input matInput formControlName="new_password" class="fs-14" required />
      </mat-form-field>
      <mat-form-field class="key password-input">
        <mat-label>Confirm password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          class="fs-14"
          required
        />
        <mat-error *ngIf="confirmPasswordControl.errors">
          {{ confirmPasswordControl.errors?.differentPassword }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions *ngIf="form">
  <div class="main-actions">
    <a class="link-btn link-gray" mat-dialog-close>cancel</a>

    <button
      mat-flat-button
      class="text-uppercase"
      [ngClass]="form.invalid ? 'inactive' : 'success'"
      [color]="form.invalid ? null : 'accent'"
      [loading]="processing"
      (click)="submit()"
    >
      Change Password
    </button>
  </div>
</div>
