import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ProcessBtnState = 'DEFAULT' | 'DISABLED' | 'LOADING';

@Component({
  selector: 'lib-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent implements OnInit {
  @Input() title: string;
  @Input() btnIcon: string;
  @Input() btnText: string;
  @Input() totalCount = 0;
  @Input() processBtn: boolean = true;
  @Input() processBtnState: ProcessBtnState = 'DEFAULT';

  @Output() process = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  processTrigger() {
    this.process.emit();
  }
}
