import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ProfileMetaType } from '@gql-types';

@Injectable({
  providedIn: 'root',
})
export class AppRoutesService {
  constructor(private router: Router) {}

  createAccountUrlTree(
    accountId: number,
    featurePath?:
      | 'activity-log'
      | 'payment-links'
      | 'payments'
      | 'balance-history'
      | 'orders'
      | 'alert-notes'
  ): UrlTree {
    return this.router.createUrlTree([
      '/account',
      accountId,
      featurePath || '',
    ]);
  }

  createProfileUrlTree(
    profileMetaType: ProfileMetaType,
    referenceId: number,
    featurePath?:
      | 'photos'
      | 'videos'
      | 'age-verification'
      | 'hundred-verification'
      | 'services'
      | 'reviews'
      | 'review-replies'
      | 'comments'
      | 'alert-notes'
      | 'settings'
  ): UrlTree {
    let profileTypeParam: string;

    if (profileMetaType === ProfileMetaType.Company) {
      profileTypeParam = 'agency';
    }
    if (
      profileMetaType === ProfileMetaType.CompanyAd ||
      profileMetaType === ProfileMetaType.Ad
    ) {
      profileTypeParam = 'escort';
    }
    if (profileMetaType === ProfileMetaType.Member) {
      profileTypeParam = 'member';
    }

    return this.router.createUrlTree([
      '/profile',
      profileTypeParam,
      referenceId,
      featurePath || '',
    ]);
  }
}
