import {
  Component,
  Inject,
  OnInit,
  Optional,
  TemplateRef,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ScConfirmDialogOptions } from './confirm-dialog';
import { ScMapperPipeFn } from '@shared/pipes';

@Component({
  selector: 'sc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ScConfirmDialogComponent implements OnInit {
  constructor(
    public scDialogRef: MatDialogRef<ScConfirmDialogComponent, boolean>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ScConfirmDialogOptions
  ) {}

  stringMapperFn: ScMapperPipeFn<string | TemplateRef<any>, boolean> = (
    content
  ) => {
    return typeof content === 'string';
  };

  ngOnInit() {}

  cancel() {
    this.scDialogRef.close(false);
  }

  confirm() {
    this.scDialogRef.close(true);
  }
}
