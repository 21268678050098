import { Inject, Injectable } from '@angular/core';
import { IS_DEVELOPMENT, LOCAL_STORAGE } from '@shared/tokens';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AccountRole } from '@gql-types';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticateGQL } from '@libs/auth/src/lib/_graphql/authenticate';
import { UpdateLastActiveDateGQL } from '@libs/auth/src/lib/_graphql/patch';

@Injectable({ providedIn: 'root' })
export class AuthTokenService {
  private readonly _jwtHelperService: JwtHelperService;
  private readonly _authTokenSubject: BehaviorSubject<string | null>;

  readonly authToken$: Observable<string | null>;

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(IS_DEVELOPMENT) private isDevelopment: boolean,
    private router: Router
  ) {
    this._jwtHelperService = new JwtHelperService();
    this._authTokenSubject = new BehaviorSubject<string | null>(
      this.getToken()
    );

    this.authToken$ = this._authTokenSubject.asObservable();
  }

  setToken(token: string) {
    this.localStorage.setItem('authtoken', token);
    this._authTokenSubject.next(this.getToken());
  }

  getToken(): string {
    const token = this.localStorage.getItem('authtoken');

    try {
      return this._jwtHelperService.isTokenExpired(token) ? null : token;
    } catch (e) {
      return null;
    }
  }

  decodeToken(token: string): any {
    try {
      return this._jwtHelperService.decodeToken(token);
    } catch (e) {
      return null;
    }
  }

  getDecodedToken(): any {
    const token = this.getToken();
    return this.decodeToken(token);
  }

  isCellarAccount(token: string) {
    const decoded = this.decodeToken(token);

    if (decoded) {
      return ![
        AccountRole.Company,
        AccountRole.Escort,
        AccountRole.Member,
      ].includes(decoded.role.toUpperCase());
    }

    return false;
  }

  removeToken(navigate: boolean = false) {
    this.localStorage.removeItem('authtoken');
    this._authTokenSubject.next(null);
    if (navigate) {
      this.router.navigateByUrl('/auth');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public redirectUrl: string | null = null;

  readonly authToken$ = this.authTokenService.authToken$;

  readonly decodedToken$ = this.authToken$.pipe(
    map((token) => this.authTokenService.decodeToken(token))
  );

  constructor(
    private router: Router,
    private authTokenService: AuthTokenService,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private authenticateGQL: AuthenticateGQL,
    private updateLastActiveDateGQL: UpdateLastActiveDateGQL
  ) {}

  authenticate(username: string, password: string): Observable<string> {
    username = `cu_${username}`;

    return this.authenticateGQL
      .mutate({
        username,
        password,
      })
      .pipe(
        map(({ data }) => data.public?.authenticate?.token),
        tap((token) => {
          if (this.authTokenService.isCellarAccount(token)) {
            this.authTokenService.setToken(token);
          } else {
            throw new Error('falsy token or role');
          }
        })
      );
  }

  patchActivity() {
    return this.authTokenService.getToken()
      ? this.updateLastActiveDateGQL.mutate()
      : of(null);
  }

  logout() {
    this.authTokenService.removeToken(true);
  }
}
