import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaymentMethod } from '@gql-types';
import { PaymentMethodsGQL } from './_graphql/payments';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private paymentMethodsGQL: PaymentMethodsGQL) {}

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethodsGQL
      .fetch()
      .pipe(map((payload) => payload.data.ops.paymentMethods));
  }
}
