import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OpsAccount } from '@gql-types';

import * as OpsAccountActions from '../actions/ops-account.actions';

export const opsAccountFeatureKey = 'ops-account';

export interface State extends EntityState<OpsAccount> {
  loading: boolean;
  error: Error;
}

export const adapter: EntityAdapter<OpsAccount> =
  createEntityAdapter<OpsAccount>({
    selectId: (model) => model.id,
    sortComparer: null,
  });

const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

const opsAccountReducer = createReducer<State, Action>(
  initialState,
  on(OpsAccountActions.loadAll, (state) => ({
    ...state,
    loading: true,
  })),
  on(OpsAccountActions.loadAllSuccess, (state, { accounts }) =>
    adapter.setAll(accounts, {
      ...state,
      loading: false,
    })
  ),
  on(OpsAccountActions.loadAllFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    OpsAccountActions.updateSkypeStatusSuccess,
    (state, { account_id, status }) =>
      adapter.mapOne(
        {
          id: account_id,
          map: (entity) => ({
            ...entity,
            ops_contact_data: {
              ...entity.ops_contact_data,
              skype: {
                ...entity.ops_contact_data.skype,
                status,
              },
            },
          }),
        },
        state
      )
  )
);

export function reducer(state: State, action: Action) {
  return opsAccountReducer(state, action);
}
