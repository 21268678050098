import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from '@gql-types';

export const loadPaymentMethods = createAction(
  '[Shared Configs] Load Payment Methods'
);
export const loadPaymentMethodsSuccess = createAction(
  '[Shared Configs] Load Payment Methods Success',
  props<{ paymentMethods: PaymentMethod[] }>()
);

export const loadPaymentMethodsFailure = createAction(
  '[Shared Configs] Load Payment Methods Failure',
  props<{ error: Error }>()
);
