import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
import * as ApolloCore from '@apollo/client/core';
export type OpsAccountPartsFragment = Pick<
  Types.OpsAccount,
  | 'id'
  | 'date_created'
  | 'email'
  | 'role'
  | 'showname'
  | 'status'
  | 'username'
  | 'last_active_date'
> & {
  ops_contact_data?: Types.Maybe<
    Pick<Types.AccountOpsContactData, 'spoken_languages'> & {
      phone_numbers?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.AccountOpsContactDataPhoneNumber, 'tags' | 'value'>
          >
        >
      >;
      skype?: Types.Maybe<
        Pick<Types.AccountOpsContactDataSkype, 'name' | 'status'>
      >;
    }
  >;
  skype?: Types.Maybe<Pick<Types.OpsAccountSkype, 'name' | 'status'>>;
};

export type OpsAccountsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OpsAccountsQuery = {
  ops?: Types.Maybe<{
    opsList?: Types.Maybe<Array<Types.Maybe<OpsAccountPartsFragment>>>;
  }>;
};

export type UpdateLastActiveDateMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type UpdateLastActiveDateMutation = {
  ops?: Types.Maybe<{
    updateLastActiveDate?: Types.Maybe<Pick<Types.MutationResponse, 'result'>>;
  }>;
};

export type UpdateOpsSkypeStatusMutationVariables = Types.Exact<{
  status: Types.AccountSkypeStatus;
  account_id: Types.Scalars['Int'];
}>;

export type UpdateOpsSkypeStatusMutation = {
  ops?: Types.Maybe<{
    updateOpsSkypeStatus?: Types.Maybe<Pick<Types.MutationResponse, 'result'>>;
  }>;
};

export type UpdateAccountPasswordByCurrentMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  new_password: Types.Scalars['String'];
}>;

export type UpdateAccountPasswordByCurrentMutation = {
  ops?: Types.Maybe<{
    updateAccountPasswordByCurrent?: Types.Maybe<
      Pick<Types.MutationResponse, 'result'>
    >;
  }>;
};

export const OpsAccountPartsFragmentDoc = gql`
  fragment OpsAccountParts on OpsAccount {
    id
    date_created
    ops_contact_data {
      phone_numbers {
        tags
        value
      }
      skype {
        name
        status
      }
      spoken_languages
    }
    email
    role
    showname
    skype {
      name
      status
    }
    status
    username
    last_active_date
  }
`;
export const OpsAccountsDocument = gql`
  query opsAccounts {
    ops {
      opsList {
        ...OpsAccountParts
      }
    }
  }
  ${OpsAccountPartsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class OpsAccountsGQL extends Apollo.Query<
  OpsAccountsQuery,
  OpsAccountsQueryVariables
> {
  document = OpsAccountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateLastActiveDateDocument = gql`
  mutation updateLastActiveDate {
    ops {
      updateLastActiveDate {
        result
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateLastActiveDateGQL extends Apollo.Mutation<
  UpdateLastActiveDateMutation,
  UpdateLastActiveDateMutationVariables
> {
  document = UpdateLastActiveDateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOpsSkypeStatusDocument = gql`
  mutation updateOpsSkypeStatus(
    $status: AccountSkypeStatus!
    $account_id: Int!
  ) {
    ops {
      updateOpsSkypeStatus(account_id: $account_id, status: $status) {
        result
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateOpsSkypeStatusGQL extends Apollo.Mutation<
  UpdateOpsSkypeStatusMutation,
  UpdateOpsSkypeStatusMutationVariables
> {
  document = UpdateOpsSkypeStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAccountPasswordByCurrentDocument = gql`
  mutation updateAccountPasswordByCurrent(
    $password: String!
    $new_password: String!
  ) {
    ops {
      updateAccountPasswordByCurrent(
        password: $password
        new_password: $new_password
      ) {
        result
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAccountPasswordByCurrentGQL extends Apollo.Mutation<
  UpdateAccountPasswordByCurrentMutation,
  UpdateAccountPasswordByCurrentMutationVariables
> {
  document = UpdateAccountPasswordByCurrentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
  extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
  extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
  extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

interface SubscriptionOptionsAlone<V>
  extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class OpsAccountSdk {
  constructor(
    private opsAccountsGql: OpsAccountsGQL,
    private updateLastActiveDateGql: UpdateLastActiveDateGQL,
    private updateOpsSkypeStatusGql: UpdateOpsSkypeStatusGQL,
    private updateAccountPasswordByCurrentGql: UpdateAccountPasswordByCurrentGQL
  ) {}

  opsAccounts(
    variables?: OpsAccountsQueryVariables,
    options?: QueryOptionsAlone<OpsAccountsQueryVariables>
  ) {
    return this.opsAccountsGql.fetch(variables, options);
  }

  opsAccountsWatch(
    variables?: OpsAccountsQueryVariables,
    options?: WatchQueryOptionsAlone<OpsAccountsQueryVariables>
  ) {
    return this.opsAccountsGql.watch(variables, options);
  }

  updateLastActiveDate(
    variables?: UpdateLastActiveDateMutationVariables,
    options?: MutationOptionsAlone<
      UpdateLastActiveDateMutation,
      UpdateLastActiveDateMutationVariables
    >
  ) {
    return this.updateLastActiveDateGql.mutate(variables, options);
  }

  updateOpsSkypeStatus(
    variables: UpdateOpsSkypeStatusMutationVariables,
    options?: MutationOptionsAlone<
      UpdateOpsSkypeStatusMutation,
      UpdateOpsSkypeStatusMutationVariables
    >
  ) {
    return this.updateOpsSkypeStatusGql.mutate(variables, options);
  }

  updateAccountPasswordByCurrent(
    variables: UpdateAccountPasswordByCurrentMutationVariables,
    options?: MutationOptionsAlone<
      UpdateAccountPasswordByCurrentMutation,
      UpdateAccountPasswordByCurrentMutationVariables
    >
  ) {
    return this.updateAccountPasswordByCurrentGql.mutate(variables, options);
  }
}
