import { Action, createReducer, on } from '@ngrx/store';
import { SharedConfigActions } from '../actions';
import { PaymentMethod } from '@gql-types';

export const SharedConfigsFeatureKey = 'shared-configs';

export interface State {
  paymentMethods: PaymentMethod[];
}

const initialState: State = {
  paymentMethods: [],
};

export const reducer = createReducer<State, Action>(
  initialState,
  on(
    SharedConfigActions.loadPaymentMethodsSuccess,
    (state, { paymentMethods }) => ({ ...state, paymentMethods })
  ),
  on(SharedConfigActions.loadPaymentMethodsFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
