import { APP_INITIALIZER, FactoryProvider, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import {
  TranslocoModule,
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoMissingHandler,
  TranslocoConfig,
  TranslocoService,
} from '@ngneat/transloco';
import { GlobalEnabledLanguage } from '@gql-types';
import { TranslocoGqlLoaderService } from './transloco-gql-loader.service';
import { EnumHelper } from '@shared/utils/enum-helper';

const TRANSLOCO_CONFIG_PROVIDER: FactoryProvider = {
  provide: TRANSLOCO_CONFIG,
  useFactory: () => {
    return translocoConfig({
      defaultLang: GlobalEnabledLanguage.En,
      availableLangs: EnumHelper.getValues<GlobalEnabledLanguage>(
        GlobalEnabledLanguage
      ),
      prodMode: environment.production,
      missingHandler: {
        useFallbackTranslation: false,
        allowEmpty: true,
        logMissingKey: true,
      },
    });
  },
};

class CustomTranslocoMissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    return `*${key}*`;
  }
}

function preloadTranslations(transloco: TranslocoService) {
  return () => transloco.load(transloco.getDefaultLang()).toPromise();
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    TRANSLOCO_CONFIG_PROVIDER,
    { provide: TRANSLOCO_LOADER, useClass: TranslocoGqlLoaderService },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: CustomTranslocoMissingHandler,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadTranslations,
      deps: [TranslocoService],
    },
  ],
})
export class TranslocoRootModule {
  constructor(private transloco: TranslocoService) {
    // this.transloco.load(GlobalEnabledLanguage.It).subscribe();
  }
}
