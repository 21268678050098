import { Inject, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AND6_TIMEZONE } from '@shared/tokens';

@Pipe({
  name: 'formatDate',
})
export class ScFormatDatePipe implements PipeTransform {
  constructor(@Inject(AND6_TIMEZONE) private timezone: string) {}

  transform(
    value: Date | string | number,
    format: string = null,
    timezone: string = null
  ): string {
    if (!value) {
      return '';
    }

    return and6FormatDate(value, format, timezone || this.timezone);
  }
}

// "2021-07-16 22:00:00"
// "2021-06-30T10:11:37.225Z"
export function and6FormatDate(
  value: Date | string | number,
  format: string = null,
  timezone: string = null
) {
  // "2021-07-16 22:00:00"
  // "2021-06-30T10:11:37.225Z"

  return moment
    .tz(value, 'UTC') // define timezone of the value, which should be utc
    .tz(timezone) // converts date and time to 'Europe/Zurich'
    .format(format);
}
