import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[scLoader]',
})
export class ScLoaderDirective implements OnChanges {
  @Input() scLoader: boolean;

  @HostBinding('class') elementClass = 'sc-loader-overlay';

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  private loaderRef;
  private wrapperClassName = 'sc-loader-wrapper';

  ngOnChanges(changes: SimpleChanges): void {
    if (this.scLoader) {
      this.loaderRef = this.loaderFactory();
      this.renderer.appendChild(this.elRef.nativeElement, this.loaderRef);
      this.renderer.addClass(this.elRef.nativeElement, 'active');
    } else {
      if (this.loaderRef) {
        this.renderer.removeChild(this.elRef.nativeElement, this.loaderRef);
        this.renderer.removeClass(this.elRef.nativeElement, 'active');
        const childList: Array<HTMLElement> = Array.from(
          this.elRef.nativeElement.children
        );
        childList.forEach((elem) => {
          elem.classList.remove('loaderSibling');
        });
      }
    }
  }

  loaderFactory(className: string = 'sc-loader-spinner') {
    const wrapper = this.renderer.createElement('div');
    const loader = this.renderer.createElement('div');

    this.renderer.addClass(wrapper, this.wrapperClassName);
    this.renderer.addClass(loader, className);
    this.renderer.appendChild(wrapper, loader);

    return wrapper;
  }
}
