<aside>
  <div class="sidenav-header">
    <i class="sc sc-cancel" (click)="closeMenu()"></i>
    <a class="sc-logo" [routerLink]="'./'">
      <img src="assets/images/and6-logo.png" class="show-hover" alt="" />
      <img src="assets/images/and6-logo-small.png" class="hide-hover" alt="" />
    </a>
  </div>
  <div class="vertical-menu">
    <div id="sidebar-menu">
      <ul class="user-menu">
        <ng-container
          *ngIf="userItem"
          [ngTemplateOutlet]="withoutSubMenu"
          [ngTemplateOutletContext]="{ menuItem: userItem }"
        >
        </ng-container>
      </ul>
      <ul class="mm-active">
        <ng-container
          *ngFor="let menuItem of menuItems; trackBy: menuItemsTrackByFn"
          [ngTemplateOutlet]="menuItem.subMenu ? withSubs : withoutSubMenu"
          [ngTemplateOutletContext]="{ menuItem: menuItem }"
        >
        </ng-container>
      </ul>
    </div>
  </div>
</aside>

<ng-template #withSubs let-menuItem="menuItem">
  <li
    hasOverflow
    *ngIf="menuItem | mapper: hasPermissionMapperFn"
    [routerLinkActive]="activeClass"
    [attr.identifier]="menuItem.id"
    [ngClass]="{
      'mm-active': openedDropdownId === menuItem.id,
      'no-anime': openedDropdownId === menuItem.id && noAnimation
    }"
  >
    <a class="has-arrow" (click)="toggleOpenedDropDown(menuItem.id)">
      <div
        *ngIf="menuItem | mapper: hasSubsAnyBadgesMapperFn:badges"
        class="indicator"
      ></div>
      <i class="sc sc-{{ menuItem.icon }}"></i>
      <span class="title">{{ menuItem.title }}</span>
    </a>
    <ul
      [@expandCollapse]="openedDropdownId === menuItem.id ? 'open' : 'close'"
      class="sub-menu"
      [ngClass]="{
        'mm-collapse': openedDropdownId === menuItem.id,
        'mm-show': openedDropdownId === menuItem.id
      }"
    >
      <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
        <li
          *ngIf="subMenuItem | mapper: hasPermissionMapperFn"
          [routerLinkActive]="activeClass"
        >
          <a
            [routerLink]="
              [subMenuItem.absolutePath ? '' : menuItem.path, subMenuItem.path]
                | mapper: emptyFilter
            "
            [queryParams]="subMenuItem.queryParams"
          >
            <span class="title">
              {{ subMenuItem.title }}

              <span *ngIf="badges[subMenuItem.badgeIdentifier]" class="badge">{{
                badges[subMenuItem.badgeIdentifier]
              }}</span>
            </span>
          </a>
        </li>
      </ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #withoutSubMenu let-menuItem="menuItem">
  <li
    *ngIf="menuItem | mapper: hasPermissionMapperFn"
    [routerLinkActive]="menuItem.path ? activeClass : ''"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    <a
      [routerLink]="[menuItem.path]"
      [queryParams]="menuItem.queryParams"
      (click)="handleLogout(menuItem.id)"
    >
      <div
        *ngIf="menuItem | mapper: hasRootAnyBadgesMapperFn:badges"
        class="indicator"
      ></div>
      <i class="sc sc-{{ menuItem.icon }}"></i>
      <span class="title"
        >{{ menuItem.title }}
        <span *ngIf="badges[menuItem.id]" class="badge pl-4">{{
          badges[menuItem.id]
        }}</span>
      </span>
    </a>
  </li>
</ng-template>
