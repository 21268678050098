import { createAction, props } from '@ngrx/store';

export const openSidenav = createAction('[Layout] Open Sidenav');
export const closeSidenav = createAction('[Layout] Close Sidenav');

export const getBadgeData = createAction('[Layout] Get Badge Data');
export const getBadgeDataSuccess = createAction(
  '[Layout] Get Badge Data Success',
  props<{ badges: { [key: string]: number } }>()
);
