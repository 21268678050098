import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-gallery-viewer',
  styles: [
    `
      .external-link {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #a4a4a4;
        outline: none;
      }

      .external-link:hover {
        color: white;
      }
    `,
  ],
  template: `<ng-template
    #itemTemplate
    let-index="index"
    let-type="type"
    let-data="data"
    let-currIndex="currIndex"
  >
    <pinch-zoom
      *ngIf="type === 'imageViewer'"
      [overflow]="'visible'"
      [limit-zoom]="4"
      [zoomControlScale]="2"
      [double-tap]="true"
      [limitPan]="true"
      [disablePan]="false"
      [autoHeight]="true"
      [wheel]="true"
    >
      <img [src]="data.src" />
    </pinch-zoom>

    <a
      target="_blank"
      class="external-link"
      *ngIf="data.original"
      [href]="data.original"
    >
      <i class="sc sc-external-link"></i>
    </a>
  </ng-template>`,
})
export class GalleryViewerComponent {
  @ViewChild('itemTemplate', { static: true }) template: TemplateRef<any>;
}
