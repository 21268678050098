import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Directive({
  selector: '[matMenuHoverTrigger]',
})
export class MatMenuHoverDirective implements OnInit {
  isInHoverBlock = false;

  constructor(private el: ElementRef) {}

  @Input() matMenuHoverTrigger: MatMenuTrigger;
  @Input() matMenuTriggerFor: any;

  ngOnInit() {
    this.el.nativeElement.addEventListener('mouseenter', () => {
      this.setHoverState(true);
      this.matMenuHoverTrigger.openMenu();

      const classes: string =
        this.matMenuTriggerFor._elementRef.nativeElement.className;

      const openMenu = document.body.querySelector(
        `.mat-menu-panel.${classes.split(' ')[0]}`
      );

      if (!openMenu) {
        this.matMenuHoverTrigger.closeMenu();
        return;
      }

      openMenu.addEventListener('mouseenter', () => {
        this.setHoverState(true);
      });
      openMenu.addEventListener('mouseleave', () => {
        const openSubMenu = document.body.querySelector(`.mat-elevation-z5`);
        if (openSubMenu) {
          openSubMenu.addEventListener('mouseenter', () => {
            this.setHoverState(true);
          });
          openSubMenu.addEventListener('mouseleave', () => {
            this.setHoverState(false);
          });
        }
        this.setHoverState(false);
      });
    });

    this.el.nativeElement.addEventListener('mouseleave', () => {
      this.setHoverState(false);
    });
  }

  private setHoverState(isInBlock: boolean) {
    this.isInHoverBlock = isInBlock;
    if (!isInBlock) {
      this.checkHover();
    }
  }

  private checkHover() {
    setTimeout(() => {
      if (!this.isInHoverBlock && this.matMenuHoverTrigger.menuOpen) {
        this.matMenuHoverTrigger.closeMenu();
      }
    }, 100);
  }
}
