import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScNotResultComponent } from './not-result.component';

@NgModule({
  declarations: [ScNotResultComponent],
  exports: [ScNotResultComponent],
  imports: [CommonModule],
})
export class ScNotResultModule {}
