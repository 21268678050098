// Angular specific
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

// Third party
import { select, Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';

// Project specific
import {
  AccountSkypeStatus,
  AccountStatus,
  OpsAccount,
  OpsRole,
} from '@gql-types';

// Core specific
import { opsAccountQuery } from '../root-store/reducers';
import { OpsAccountSdk } from './_graphql/ops-accounts';
import { AuthService } from '@libs/core/src';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly opsAccounts$ = this.store.pipe(
    select(opsAccountQuery.selectAll),
    concatLatestFrom(() => this.authAccount$),
    map(([opsAccounts, authAccount]) => {
      return authAccount?.role === OpsRole.Admin
        ? opsAccounts
        : opsAccounts.filter((o) => o.status === AccountStatus.Active);
    })
  );

  readonly opsAccountEntityMap$ = this.store.pipe(
    select(opsAccountQuery.selectEntities)
  );

  readonly opsAccountById$$ = (id: number) =>
    this.store.pipe(
      select(opsAccountQuery.selectById, { id }),
      map(
        (opsAccount: OpsAccount) =>
          opsAccount || ({ id: null, showname: 'Mr. Unknown' } as OpsAccount)
      )
    );

  readonly authAccount$ = this.authService.decodedToken$.pipe(
    filter((decodedToken) => !!decodedToken),
    switchMap((decodedToken) => this.opsAccountById$$(decodedToken.id)),
    tap((account) => !account.id && this.authService.logout())
  );

  constructor(
    private authService: AuthService,
    private store: Store,
    private opsAccountSdk: OpsAccountSdk
  ) {}

  getOpsAccounts(): Observable<OpsAccount[]> {
    return this.opsAccountSdk
      .opsAccounts(
        {},
        {
          errorPolicy: 'all',
        }
      )
      .pipe(
        map(({ data, errors }) => {
          let opsList = (data.ops!.opsList || []).filter(Boolean);

          const systemOpsAccount: OpsAccount = {
            id: 1,
            date_created: null,
            email: undefined,
            ops_contact_data: undefined,
            skype: undefined,
            role: OpsRole.Admin,
            status: AccountStatus.Active,
            username: 'system',
            showname: 'system',
          };

          return [systemOpsAccount, ...opsList];
        })
      );
  }

  updateLastActiveDate() {
    return this.opsAccountSdk
      .updateLastActiveDate()
      .pipe(map(({ data }) => data.ops.updateLastActiveDate));
  }

  updateOpsSkypeStatus(account_id: number, status: AccountSkypeStatus) {
    return this.opsAccountSdk
      .updateOpsSkypeStatus({ account_id, status })
      .pipe(map(({ data }) => data.ops.updateOpsSkypeStatus));
  }

  updatePassword(password: string, new_password: string) {
    return this.opsAccountSdk
      .updateAccountPasswordByCurrent({ password, new_password })
      .pipe(map(({ data }) => data.ops.updateAccountPasswordByCurrent));
  }
}
