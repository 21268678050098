import { createReducer, on } from '@ngrx/store';
import { GraphqlWebsocketStatus } from '@libs/core/src/lib/types';
import { websocketStatusChange } from '../actions/gql-ws.actions';

export const graphqlWebsocketFeatureKey = 'graphql-websocket';

export interface State {
  status: GraphqlWebsocketStatus;
  error: string | null;
}

export const initialState: State = {
  status: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(websocketStatusChange, (state, { type, error, status }) => ({
    status,
    error,
  }))
);

export const selectStatus = (state: State) => state.status;
export const selectError = (state: State) => state.error;
