import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { ScConfirmDialogComponent } from './confirm-dialog.component';
import { SC_CONFIRM_DIALOG_DEFAULT_OPTIONS } from './confirm-dialog';
import { ScPipesModule } from '@shared/pipes';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ScPipesModule,
  ],
  declarations: [ScConfirmDialogComponent],
  providers: [],
})
export class ScConfirmDialogModule {
  static forRoot(): ModuleWithProviders<ScConfirmDialogModule> {
    return {
      ngModule: ScConfirmDialogModule,
      providers: [{ provide: SC_CONFIRM_DIALOG_DEFAULT_OPTIONS, useValue: {} }],
    };
  }
}
