import { ScFormErrorsMap } from './types';

function getMessageByDefaultPattern(pattern: string) {
  const message = 'Allowed only';
  switch (pattern) {
    case '^[\\-_a-z0-9\\s]+$': {
      return `${message} following chars: 'A-Z a-z 0-9 _ -'`;
    }
    case '[0-9]*':
    case '^[0-9]\\d*$': {
      return `${message} digits.`;
    }

    default: {
      return 'Invalid field';
    }
  }
}

export const defaultErrorsMap: ScFormErrorsMap = {
  min: (pattern) => `Value must be at least ${pattern.min}`,
  max: (pattern) => `Value must be less or equal than ${pattern.max}`,
  email: () => 'Invalid email address',
  pattern: (pattern) => getMessageByDefaultPattern(pattern.requiredPattern),
  required: () => 'Please enter a value',
  minlength: (pattern) =>
    `Field must contains at least ${pattern.requiredLength} chars.`,
  maxlength: (pattern) =>
    `Field must contains less or equal than ${pattern.requiredLength} chars.`,
  declined: (error) => `Declined`,
};
