import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { interval, Subject, timer } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import {} from 'date-fns';
import { getTimezoneOffset, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { AND6_TIMEZONE } from '@shared/tokens';

@Directive({
  selector: '[digitalClock]',
})
export class DigitalClockDirective implements OnInit, OnDestroy {
  _destroyed$ = new Subject<void>();

  timer$ = interval(1000);
  hours = this.renderer.createElement('span');
  minute = this.renderer.createElement('span');
  separator = this.renderer.createElement('span');

  constructor(
    @Inject(AND6_TIMEZONE) private timezone: string,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  private getClockData() {
    const date = utcToZonedTime(new Date(), this.timezone);

    return {
      hours: ('0' + date.getHours().toString()).slice(-2),
      minutes: ('0' + date.getMinutes().toString()).slice(-2),
      seconds: ('0' + date.getSeconds().toString()).slice(-2),
    };
  }

  ngOnInit(): void {
    const parent = this.elementRef.nativeElement;
    this.renderer.appendChild(parent, this.hours);
    this.renderer.appendChild(parent, this.separator);
    this.renderer.appendChild(parent, this.minute);

    this.renderer.addClass(this.hours, 'dc-hours');
    this.renderer.addClass(this.separator, 'dc-separator');
    this.renderer.addClass(this.minute, 'dc-minutes');

    const hv = this.renderer.createText('00');
    const sv = this.renderer.createText(':');
    const mv = this.renderer.createText('00');

    this.renderer.appendChild(this.hours, hv);
    this.renderer.appendChild(this.separator, sv);
    this.renderer.appendChild(this.minute, mv);

    const { hours, minutes } = this.getClockData();
    this.renderer.setValue(hv, hours);
    this.renderer.setValue(mv, minutes);

    this.timer$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      const { hours, minutes, seconds } = this.getClockData();
      this.renderer.setValue(hv, hours);
      this.renderer.setValue(mv, minutes);
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
