<div class="sc-table-container">
  <table mat-table [trackBy]="dataTrackByFn" [dataSource]="dataSource">
    <ng-container *ngFor="let key of displayedColumns" [matColumnDef]="key">
      <th [noWrap]="true" mat-header-cell *matHeaderCellDef [ngSwitch]="key">
        <ng-container
          [ngTemplateOutlet]="tableKeyName"
          [ngTemplateOutletContext]="{ key: key, type: type }"
        ></ng-container>
      </th>
      <td [noWrap]="true" mat-cell *matCellDef="let element" [ngSwitch]="key">
        <ng-container
          *ngSwitchCase="'reference_id'"
          [ngTemplateOutlet]="profileLink"
          [ngTemplateOutletContext]="{
            ref_id: element[key],
            profile_type: element.profile_type,
            highlight: {
              key: key,
              data: element.highlight
            }
          }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'account_id'"
          [ngTemplateOutlet]="accountLink"
          [ngTemplateOutletContext]="{
            acc_id: element[key],
            highlight: {
              key: key,
              data: element.highlight
            }
          }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'profile_type'"
          [ngTemplateOutlet]="profileType"
          [ngTemplateOutletContext]="{
            type: element[key]
          }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'phone_numbers'"
          [ngTemplateOutlet]="arrayData"
          [ngTemplateOutletContext]="{
            data: element[key],
            highlight: {
              key: key,
              data: element.highlight
            }
          }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'emails'"
          [ngTemplateOutlet]="arrayData"
          [ngTemplateOutletContext]="{
            data: element[key],
            highlight: {
              key: key,
              data: element.highlight
            }
          }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'role'"
          [ngTemplateOutlet]="titleCaseData"
          [ngTemplateOutletContext]="{ text: element[key] }"
        ></ng-container>

        <ng-container
          *ngSwitchCase="'account_status'"
          [ngTemplateOutlet]="titleCaseData"
          [ngTemplateOutletContext]="{ text: element[key] }"
        ></ng-container>

        <ng-container *ngSwitchCase="'ops_account_id'">
          {{ element[key] | mapper: getOpsAccountShowname }}
        </ng-container>

        <ng-container
          *ngSwitchCase="'is_deleted'"
          [ngTemplateOutlet]="profileDeleted"
          [ngTemplateOutletContext]="{ deleted: element[key] }"
        ></ng-container>

        <ng-template ngSwitchDefault>
          <span
            [innerHTML]="
              element[key] || '-'
                | highlight: searchQuery:{ key: key, data: element.highlight }
            "
          ></span>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<!-- Pagination -->
<div [class.d-none]="dataSource?.filteredData.length <= 10">
  <mat-paginator> </mat-paginator>
</div>

<!--Table Column Names-->
<ng-template #tableKeyName let-key="key" let-type="type">
  <ng-container [ngSwitch]="key">
    <ng-template [ngSwitchCase]="'account_id'">Account ID</ng-template>
    <ng-template [ngSwitchCase]="'username'">Username</ng-template>
    <ng-template [ngSwitchCase]="'email'">Email</ng-template>
    <ng-template [ngSwitchCase]="'role'">Role</ng-template>
    <ng-template [ngSwitchCase]="'account_status'">
      {{ type === 'PROFILE' ? 'Account' : '' }} Status
    </ng-template>
    <ng-template [ngSwitchCase]="'ops_account_id'">Sales</ng-template>
    <ng-template [ngSwitchCase]="'reference_id'">Profile ID</ng-template>
    <ng-template [ngSwitchCase]="'showname'">Showname</ng-template>
    <ng-template [ngSwitchCase]="'emails'">Emails</ng-template>
    <ng-template [ngSwitchCase]="'phone_numbers'">Phone Numbers</ng-template>
    <ng-template [ngSwitchCase]="'profile_type'">Type</ng-template>
    <ng-template [ngSwitchCase]="'is_deleted'">Deleted</ng-template>
  </ng-container>
</ng-template>

<!--Profile Link-->
<ng-template
  #profileLink
  let-ref_id="ref_id"
  let-profile_type="profile_type"
  let-highlight="highlight"
>
  <a
    class="btn-link link-profile"
    [routerLink]="[
      { ref_id: ref_id, type: profile_type } | mapper: getProfileRouterLink
    ]"
    [innerHTML]="ref_id | highlight: searchQuery:highlight"
  >
  </a>
</ng-template>

<!--Account Link-->
<ng-template #accountLink let-acc_id="acc_id" let-highlight="highlight">
  <a
    class="btn-link link-primary"
    [routerLink]="[{ account_id: acc_id } | mapper: getAccountRouterLink]"
    [innerHTML]="acc_id | highlight: searchQuery:highlight"
  >
  </a>
</ng-template>

<!--Profile Type-->
<ng-template #profileType let-type="type">
  <ng-container [ngSwitch]="type">
    <ng-template [ngSwitchCase]="'AD'">Escort</ng-template>
    <ng-template [ngSwitchCase]="'COMPANY_AD'">Agency Escort</ng-template>
    <ng-template [ngSwitchCase]="'COMPANY'">Agency</ng-template>
    <ng-template [ngSwitchCase]="'MEMBER'">Member</ng-template>
  </ng-container>
</ng-template>

<!--Arrays Data __ Phone Numbers & Emails-->
<ng-template #arrayData let-data="data" let-highlight="highlight">
  <ng-container *ngFor="let item of data; let i = index">
    <ng-template [ngIf]="i !== 0">,</ng-template>
    <span [innerHTML]="item | highlight: searchQuery:highlight"></span>
  </ng-container>

  <ng-template [ngIf]="!data.length">-</ng-template>
</ng-template>

<!--Text Transforms TitleCase __ Role, Account Status-->
<ng-template #titleCaseData let-text="text">
  {{ text | titlecase }}
</ng-template>

<!--Is Deleted Profile-->
<ng-template #profileDeleted let-deleted="deleted">
  <span [class.text-danger]="deleted">{{ deleted ? 'Yes' : 'No' }}</span>
</ng-template>
