<ng-content select="[toggleSideMenu]"></ng-content>

<mat-form-field class="no-padding">
  <input
    matInput
    class="fs-14"
    (keyup.enter)="search()"
    [formControl]="searchControl"
    placeholder="Search for an account, profile ..."
  />
  <i
    class="sc sc-back text-info show-md cursor-pointer"
    matPrefix
    (click)="closeSearchBar()"
  ></i>
  <i
    class="sc sc-search text-info cursor-pointer"
    matSuffix
    (click)="search()"
  ></i>
</mat-form-field>
