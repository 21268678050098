import { Inject, Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';

import { environment } from '@env/environment';
import { LOCATION } from '@shared/tokens/location';

@Injectable()
export class NavigationEffects {
  constructor(
    private router: Router,
    @Inject(LOCATION) private location: Location
  ) {}

  readonly routerLoadError$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((event) => event instanceof NavigationError),
        filter((event: NavigationError) => {
          const chunkFailedMessage = /Loading chunk \S+ failed/i;
          return chunkFailedMessage.test(event.error.message);
        }),
        tap((event) => {
          this.location.href = environment.cellarUrl + event.url;
        })
      ),
    { dispatch: false }
  );
}
