import { createAction, props } from '@ngrx/store';
import { AccountSkypeStatus, OpsAccount } from '@gql-types';

export const loadAll = createAction('[Ops-Account] Load All');
export const loadAllSuccess = createAction(
  '[Ops-Account] Load All Success',
  props<{ accounts: OpsAccount[] }>()
);
export const loadAllFailure = createAction(
  '[Ops-Account] Load All Failure',
  props<{ error: Error }>()
);

export const updateLastActiveDate = createAction(
  '[Ops-Account] Update Last Active Date'
);
export const updateSkypeStatus = createAction(
  '[Ops-Account] Update Skype Status',
  props<{ account_id: number; status: AccountSkypeStatus }>()
);
export const updateSkypeStatusSuccess = createAction(
  '[Ops-Account] Update Skype Status Success',
  props<{ account_id: number; status: AccountSkypeStatus }>()
);
export const updateSkypeStatusFailure = createAction(
  '[Ops-Account] Update Skype Status Failure',
  props<{ error: Error }>()
);
