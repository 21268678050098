import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LetModule, PushModule } from '@ngrx/component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ScPipesModule } from '@shared/pipes';
import { ScNotResultModule } from '@shared/not-result';

import { SearchbarComponent } from './searchbar.component';
import { SearchbarResultDialogComponent } from './searchbar-result-dialog/searchbar-result-dialog.component';
import { SearchResultTableComponent } from './search-result-table/search-result-table.component';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
  declarations: [
    SearchbarComponent,
    SearchbarResultDialogComponent,
    SearchResultTableComponent,
    HighlightPipe,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    NgScrollbarModule,
    ScNotResultModule,
    RouterModule,
    ScPipesModule,
    MatProgressSpinnerModule,
    LetModule,
    PushModule,
  ],
  exports: [SearchbarComponent],
})
export class SearchbarModule {}
