export class EnumHelper {
  static getKeysAndValues<T extends number>(e: any) {
    return EnumHelper.getKeys(e).map((n) => ({ key: n, value: e[n] as T }));
  }

  static getObject<T>(e: T): { [P in keyof T]: T[P] } {
    return Object.assign(e as any);
  }

  static getKeys<T>(e: T) {
    return Object.keys(e) as Array<keyof T>;
  }

  static getValues<T extends string>(e: any) {
    return EnumHelper.getObjValues(e) as T[];
  }

  static replaceString(arg: string[]) {
    return arg.map((item) => item.replace(/_/g, ' '));
  }

  static getSelectList<T extends string, U>(
    e: any,
    stringConverter: (arg: U) => string
  ) {
    const selectList = new Map<T, string>();
    this.getValues(e).forEach((val) =>
      selectList.set(val as T, stringConverter(val as unknown as U))
    );
    return selectList;
  }

  static getSelectListAsArray<T extends string, U>(
    e: any,
    stringConverter: (arg: U) => string
  ) {
    return Array.from(this.getSelectList(e, stringConverter), (value) => ({
      value: value[0] as T,
      presentation: value[1],
    }));
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.values(e);
  }
}
