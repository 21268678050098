import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
export type OpsPrivateMessageFragment = Pick<
  Types.OpsToolsPrivateMessage,
  | '_id'
  | 'sender_account_id'
  | 'recipient_account_id'
  | 'subject'
  | 'message'
  | 'date_sent'
  | 'is_read'
  | 'is_urgent'
  | 'is_forwarded'
>;

export type PrivateMessageSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type PrivateMessageSubscription = {
  opsToolsPrivateMessageCreatedForWS: OpsPrivateMessageFragment;
};

export const OpsPrivateMessageFragmentDoc = gql`
  fragment OpsPrivateMessage on OpsToolsPrivateMessage {
    _id
    sender_account_id
    recipient_account_id
    subject
    message
    date_sent
    is_read
    is_urgent
    is_forwarded
  }
`;
export const PrivateMessageDocument = gql`
  subscription privateMessage {
    opsToolsPrivateMessageCreatedForWS {
      ...OpsPrivateMessage
    }
  }
  ${OpsPrivateMessageFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PrivateMessageGQL extends Apollo.Subscription<
  PrivateMessageSubscription,
  PrivateMessageSubscriptionVariables
> {
  document = PrivateMessageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
