// Angular specific
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pluck,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

// Third party
import { BreakpointObserver } from '@angular/cdk/layout';
import { ScConfirmDialog } from '@shared/confirm-dialog';
import { Store } from '@ngrx/store';

// Project specific
import { AuthService } from '@libs/core/src';
import { AccountService } from '@core/services';
import { OpsAccountActions } from '@core/root-store';
import { UpdateOpsPasswordService } from '@shared/update-ops-password';

// Module specific
import { AccountSkypeStatus } from '@gql-types';
import { LayoutService, Breakpoints } from './layout.service';
import { LayoutAnimations } from './layout.animation';
import { Menu } from './layout.types';

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    LayoutAnimations.backdropToggle(200),
    LayoutAnimations.defaultRippleAnimationConfig('inLoaderAnimation'),
    LayoutAnimations.inContentAnimation(),
  ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  showSidenav$ = this.layoutService.showSidenav$;

  openedDropdownId = new BehaviorSubject<Menu.ItemIdentifier>(null);

  menuItems$: Observable<Array<Menu.Item>> = this.layoutService.menuItems$;

  badges$ = this.layoutService.badges$;

  authAccount$ = this.accountService.authAccount$;
  authRole$ = this.authAccount$.pipe(map((a) => a.role));
  desktopMinHeight$ = this.breakpointObserver
    .observe('(max-height: 870px)')
    .pipe(map((s) => s.matches));

  userItem$ = this.authAccount$.pipe(
    map((account) => ({
      id: Menu.ItemIdentifier.UserInfo,
      title: `${account.showname} (${account.role?.toLowerCase()})`,
      icon: 'avatar',
      path: '/i-dont-know',
    }))
  );

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.layoutService.setVerticalHeightVariable();
  }

  constructor(
    private router: Router,
    private store: Store,
    private layoutService: LayoutService,
    private authService: AuthService,
    private accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private scConfirm: ScConfirmDialog,
    private updateOpsPasswordService: UpdateOpsPasswordService
  ) {}

  ngOnInit() {
    this.layoutService.setVerticalHeightVariable();
    this.layoutService.updateBadgeData();

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.layoutService.updateBadgeData());

    this.router.events
      .pipe(
        filter((_) =>
          this.breakpointObserver.isMatched([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Tablet,
            Breakpoints.TabletLarge,
            Breakpoints.TabletLargeIncluded,
          ])
        ),
        debounceTime(50),
        takeUntil(this._destroyed)
      )
      .subscribe(() => this.layoutService.closeSidenav());

    this.layoutService.isWeb$
      .pipe(
        withLatestFrom(this.showSidenav$),
        filter(([_, isShow]) => !!isShow),
        takeUntil(this._destroyed)
      )
      .subscribe(() => this.layoutService.closeSidenav());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  toggleMenu(value?: boolean) {
    this.showSidenav$.pipe(take(1)).subscribe((showSidenav) => {
      if (value !== showSidenav) {
        showSidenav
          ? this.layoutService.closeSidenav()
          : this.layoutService.openSidenav();
      }
    });
  }

  toggleMenuByHover(value?: boolean) {
    this.layoutService.isWeb$
      .pipe(withLatestFrom(this.desktopMinHeight$))
      .pipe(
        take(1),
        filter(([isWeb, isWebMinHeight]) => !!isWeb && isWebMinHeight),
        takeUntil(this._destroyed)
      )
      .subscribe(() => this.toggleMenu(value));
  }

  toggleDropdownId(value: Menu.ItemIdentifier) {
    this.openedDropdownId.next(value);
  }

  logout() {
    this.authService.logout();
  }

  onSkypeStatusChange(status: AccountSkypeStatus) {
    this.authAccount$
      .pipe(
        take(1),
        tap((account) => {
          this.store.dispatch(
            OpsAccountActions.updateSkypeStatus({
              account_id: account.id,
              status,
            })
          );
        })
      )
      .subscribe();
  }

  onPasswordChange() {
    this.updateOpsPasswordService
      .openUpdatePassDialog()
      .subscribe(({ changed }) => {});
  }
}
