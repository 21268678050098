import { NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { ScMapperPipe } from './mapper.pipe';
import { ScFormatDatePipe } from './format-date.pipe';
import { KeyToTextPipe } from './key-to-text.pipe';
import { SafePipe } from './safe.pipe';
import { PaymentMethodPipe } from './payment-method.pipe';
import { StripHtmlPipe } from './strip-html';
import { UntilTimePipe } from './until-time.pipe';

@NgModule({
  declarations: [
    ScMapperPipe,
    ScFormatDatePipe,
    KeyToTextPipe,
    SafePipe,
    StripHtmlPipe,
    PaymentMethodPipe,
    UntilTimePipe,
  ],
  exports: [
    ScMapperPipe,
    ScFormatDatePipe,
    KeyToTextPipe,
    SafePipe,
    StripHtmlPipe,
    PaymentMethodPipe,
    UntilTimePipe,
  ],
  providers: [AsyncPipe],
})
export class ScPipesModule {}
