import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyToText',
})
export class KeyToTextPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    value = String(value);

    if (!value || (value && typeof value !== 'string')) {
      return value;
    }

    const replacement = value.replace(new RegExp('_', 'g'), ' ').toLowerCase();
    return replacement.charAt(0).toUpperCase() + replacement.slice(1);
  }
}
