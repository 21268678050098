<div class="sc-main-layout">
  <div
    class="tablet-overlay"
    *ngIf="showSidenav$ | async"
    [@backdropToggle]
    (click)="toggleMenu()"
  ></div>

  <lib-sidenav
    [badges]="badges$ | async"
    [role]="authRole$ | async"
    [userItem]="userItem$ | async"
    [menuItems]="menuItems$ | async"
    [showSidenav]="showSidenav$ | async"
    [openedDropdownId]="openedDropdownId.value"
    [class.collapsed]="!(showSidenav$ | async)"
    (mouseenter)="toggleMenuByHover(true)"
    (mouseleave)="toggleMenuByHover(false)"
    (closed)="toggleMenu(false)"
    (toggleDropdownId)="toggleDropdownId($event)"
    (logout)="logout()"
  ></lib-sidenav>

  <div class="sc-main-content-wrapper">
    <div class="sc-content">
      <lib-header
        *ngIf="authAccount$ | async"
        [showSidenav]="showSidenav$ | async"
        [authAccount]="authAccount$ | async"
        (menuToggled)="toggleMenu($event)"
        (logout)="logout()"
        (skypeStatusChange)="onSkypeStatusChange($event)"
        (passwordChange)="onPasswordChange()"
      ></lib-header>

      <div [@inContentAnimation]>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
