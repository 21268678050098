import { FactoryProvider } from '@angular/core';

import {
  APOLLO_CONNECTOR_OPTIONS,
  ApolloConnectorOptions,
  createAuthLink,
  isQueryOrMutation,
} from '@sceon/apollo-connector';
import { onError } from '@apollo/client/link/error';

import { environment } from '@env/environment';
import {
  AuthTokenService,
  GqlWsService,
  SentryReporterService,
} from '@core/services';
import { of } from 'rxjs';
import { GraphQLError } from 'graphql';

function apolloConnectorOptionsFactory(
  authTokenService: AuthTokenService,
  gqlWsService: GqlWsService,
  sentryReporter: SentryReporterService
): ApolloConnectorOptions {
  const authMiddlewareLink = createAuthLink((operation, forward) => {
    if (!isQueryOrMutation(operation)) {
      return forward(operation);
    }

    if (operation.operationName === 'authenticate') {
      return forward(operation);
    }

    if (!Boolean(authTokenService.getDecodedToken())) {
      authTokenService.removeToken(true);

      return of({
        errors: [new GraphQLError('jwt expired')],
        data: null,
      }) as any;
    }

    return forward(operation);
  });

  const errorLink = onError((errorResponse) => {
    sentryReporter.reportGraphqlErrors(errorResponse);
  });

  const links = [authMiddlewareLink, errorLink];

  return {
    name: 'AND6_CELLAR',
    graphqlUrl: {
      http: environment.graphqlHttpUrl,
      ws: environment.graphqlWsUrl,
    },
    authToken$: authTokenService.authToken$,
    timeout: 12999, // server ping interval is 10000 ms (KeepAlive behavior - ping payload {"type":"ka"})
    links,
    inMemoryCacheConfig: {
      addTypename: false,
      resultCaching: false,
    },
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      watchQuery: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
    },
    connectionCallback: (error: any, result?: any) => {
      // right now server doesn't support connection_ack result(payload)
      gqlWsService.init(error ? error.message : null);
    },
    onConnected: () => {
      gqlWsService.connected();
    },
    onDisconnected: () => {
      gqlWsService.disconnected();
    },
    onReconnected: () => {
      gqlWsService.reconnected();
    },
    onError: (error: Error) => {
      console.log(error);
      gqlWsService.error(error.message);
    },
  };
}

export const AND6_APOLLO_CONNECTOR_PROVIDER: FactoryProvider = {
  provide: APOLLO_CONNECTOR_OPTIONS,
  useFactory: apolloConnectorOptionsFactory,
  deps: [AuthTokenService, GqlWsService, SentryReporterService],
};
