import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

import { CellarAggregatorSearchItemType, OpsAccount } from '@gql-types';
import { AppRoutesService } from '@libs/core/src/lib/services/app-routes.service';
import { ScMapperPipeFn } from '@shared/pipes';

@Component({
  selector: 'lib-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  matPaginator: MatPaginator;

  @Input() searchQuery: string;
  @Input() type: CellarAggregatorSearchItemType;
  @Input() opsAccounts: OpsAccount[];
  @Input() dataSource: MatTableDataSource<any>;
  @Input() displayedColumns: string[];

  dataTrackByFn = (index: number, item: any) => index;

  getAccountRouterLink: ScMapperPipeFn<{ account_id }, string> = ({
    account_id,
  }) => this.appRoutesService.createAccountUrlTree(account_id).toString();

  getProfileRouterLink: ScMapperPipeFn<{ type; ref_id }, string> = ({
    type,
    ref_id,
  }) => this.appRoutesService.createProfileUrlTree(type, ref_id).toString();

  getOpsAccountShowname: ScMapperPipeFn<number, string> = (id) =>
    this.opsAccounts.find((a) => a.id === id)?.showname || '-';

  constructor(private appRoutesService: AppRoutesService) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.matPaginator;
  }
}
