import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
import * as ApolloCore from '@apollo/client/core';
export type ProfileSearchItemFragment = Pick<
  Types.CellarAggregatorProfileSearchItem,
  | 'type'
  | 'reference_id'
  | 'account_id'
  | 'showname'
  | 'emails'
  | 'phone_numbers'
  | 'profile_type'
  | 'is_deleted'
  | 'account_status'
>;

export type AccountSearchItemFragment = Pick<
  Types.CellarAggregatorAccountSearchItem,
  | 'type'
  | 'account_id'
  | 'username'
  | 'email'
  | 'role'
  | 'ops_account_id'
  | 'account_status'
>;

export type CellarAggregatorSearchQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
  min_score?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type CellarAggregatorSearchQuery = {
  ops?: Types.Maybe<{
    cellarAggregatorSearch?: Types.Maybe<
      Pick<Types.CellarAggregatorSearchResponse, 'count'> & {
        result?: Types.Maybe<
          Array<
            Types.Maybe<
              | (Pick<Types.CellarAggregatorProfileSearchItem, 'account_id'> & {
                  highlight?: Types.Maybe<
                    Pick<
                      Types.CellarAggregatorSearchItemHighlight,
                      | 'account_id'
                      | 'email'
                      | 'emails'
                      | 'phone_numbers'
                      | 'profile_id'
                      | 'reference_id'
                      | 'showname'
                      | 'username'
                    >
                  >;
                } & ProfileSearchItemFragment)
              | (Pick<Types.CellarAggregatorAccountSearchItem, 'account_id'> & {
                  highlight?: Types.Maybe<
                    Pick<
                      Types.CellarAggregatorSearchItemHighlight,
                      | 'account_id'
                      | 'email'
                      | 'emails'
                      | 'phone_numbers'
                      | 'profile_id'
                      | 'reference_id'
                      | 'showname'
                      | 'username'
                    >
                  >;
                } & AccountSearchItemFragment)
            >
          >
        >;
      }
    >;
  }>;
};

export const ProfileSearchItemFragmentDoc = gql`
  fragment profileSearchItem on CellarAggregatorProfileSearchItem {
    type
    reference_id
    account_id
    showname
    emails
    phone_numbers
    profile_type
    is_deleted
    account_status
  }
`;
export const AccountSearchItemFragmentDoc = gql`
  fragment accountSearchItem on CellarAggregatorAccountSearchItem {
    type
    account_id
    username
    email
    role
    ops_account_id
    account_status
  }
`;
export const CellarAggregatorSearchDocument = gql`
  query cellarAggregatorSearch($query: String!, $min_score: Int) {
    ops {
      cellarAggregatorSearch(query: $query, min_score: $min_score) {
        count
        result {
          account_id
          highlight {
            account_id
            email
            emails
            phone_numbers
            profile_id
            reference_id
            reference_id
            showname
            username
          }
          ... on CellarAggregatorProfileSearchItem {
            ...profileSearchItem
          }
          ... on CellarAggregatorAccountSearchItem {
            ...accountSearchItem
          }
        }
      }
    }
  }
  ${ProfileSearchItemFragmentDoc}
  ${AccountSearchItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CellarAggregatorSearchGQL extends Apollo.Query<
  CellarAggregatorSearchQuery,
  CellarAggregatorSearchQueryVariables
> {
  document = CellarAggregatorSearchDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
  extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
  extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
  extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

interface SubscriptionOptionsAlone<V>
  extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class SearchbarSDK {
  constructor(private cellarAggregatorSearchGql: CellarAggregatorSearchGQL) {}

  cellarAggregatorSearch(
    variables: CellarAggregatorSearchQueryVariables,
    options?: QueryOptionsAlone<CellarAggregatorSearchQueryVariables>
  ) {
    return this.cellarAggregatorSearchGql.fetch(variables, options);
  }

  cellarAggregatorSearchWatch(
    variables: CellarAggregatorSearchQueryVariables,
    options?: WatchQueryOptionsAlone<CellarAggregatorSearchQueryVariables>
  ) {
    return this.cellarAggregatorSearchGql.watch(variables, options);
  }
}
