// Angular specific
import { NgModule } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

// NGRX specific
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Module specific
import { metaReducers, ROOT_REDUCERS } from './reducers';
import { rootEffects } from './effects';

@NgModule({
  imports: [
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),

    EffectsModule.forRoot(rootEffects),

    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),

    StoreDevtoolsModule.instrument({}),
  ],
  providers: [TitleCasePipe],
})
export class RootStoreModule {}
