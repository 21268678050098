import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  AuthGuard,
  CellarRoute,
  CoreComponent,
  RoleGuard,
} from '@libs/core/src';
import { OpsRole } from '@gql-types';
import { GuestGuard } from '@core/guards';

const routes: CellarRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'billing/orders/:id',
    loadChildren: () =>
      import('@shared/order-details/order-view/order-view.module').then(
        (m) => m.OrderViewModule
      ),
  },
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: 'access-denied',
        loadChildren: () =>
          import('@libs/access-denied/src/lib/access-denied.module').then(
            (m) => m.AccessDeniedModule
          ),
      },
      {
        path: 'dashboard',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'dashboard',
        },
        loadChildren: () =>
          import('@libs/dashboard/src/lib/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'profile-search',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'profile search',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.EscortProfilesModule),
      },
      {
        path: 'company-profile-search',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'company profile search',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.CompanyProfilesModule),
      },
      {
        path: 'member-profile-search',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'member profile search',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.MemberProfilesModule),
      },
      {
        path: 'persoanl-url',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'personal url',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.PersonalUrlModule),
      },
      {
        path: 'hundred-verification',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'hundred verifications',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.HundredVerificationsModule),
      },
      {
        path: 'age-verification',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'age certifications',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.AgeVerificationsModule),
      },
      {
        path: 'not-verified-accounts',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'not verified accounts',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.NotVerifiedAccountsModule),
      },
      {
        path: 'banned-accounts',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'banned accounts',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.BannedAccountsModule),
      },
      {
        path: 'chat-search',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'chat-search',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.ChatModule),
      },
      {
        path: 'ip-cookie-duplicities',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'ip cookie duplicities',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.IpCookieDuplicitiesModule),
      },
      {
        path: 'banners',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'banners',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.BannersModule),
      },
      {
        path: 'status-messages',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'status messages',
        },
        loadChildren: () =>
          import('@libs/search/src').then((m) => m.StatusMessagesModule),
      },
      {
        path: 'tickets',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'Tickets',
        },
        loadChildren: () =>
          import('@libs/tickets/src/lib/tickets.module').then(
            (m) => m.TicketsModule
          ),
      },
      {
        path: 'ai-moderated',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'AI Moderated',
        },
        loadChildren: () =>
          import('@libs/ai-moderation/src/lib/ai-moderation.module').then(
            (m) => m.AiModerationModule
          ),
      },
      {
        path: 'top-10-comments',
        canActivate: [RoleGuard],
        data: {
          title: 'Top 10 Comments',
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
        },
        loadChildren: () =>
          import('@libs/top-ten-comments/src/lib/top-ten-comments.module').then(
            (m) => m.TopTenCommentsModule
          ),
      },
      {
        path: 'billing',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'billing',
        },
        loadChildren: () =>
          import('@libs/billing/src/lib/billing.module').then(
            (m) => m.BillingModule
          ),
      },
      {
        path: 'client-blacklist',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'client blacklist',
        },
        loadChildren: () =>
          import('@libs/client-blacklist/src/lib/client-blacklist.module').then(
            (m) => m.ClientBlacklistModule
          ),
      },
      {
        path: 'messages',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'messages',
        },
        loadChildren: () =>
          import('@libs/messages/src/lib/messages.module').then(
            (m) => m.MessagesModule
          ),
      },
      {
        path: 'system',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'system',
        },
        loadChildren: () =>
          import('@libs/system/src/lib/system.module').then(
            (m) => m.SystemModule
          ),
      },
      {
        path: 'templates',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'templates',
        },
        loadChildren: () =>
          import('@libs/templates/src/lib/templates.module').then(
            (m) => m.TemplatesModule
          ),
      },
      {
        path: 'newsletter',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'newsletter',
        },
        loadChildren: () =>
          import('@libs/newsletter/src/lib/newsletter.module').then(
            (m) => m.NewsletterModule
          ),
      },
      {
        path: 'seo',
        canActivate: [RoleGuard],
        data: {
          title: 'seo',
        },
        loadChildren: () =>
          import('@libs/seo/src/lib/seo.module').then((m) => m.SeoModule),
      },
      {
        path: 'support',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales] },
          title: 'support',
        },
        loadChildren: () =>
          import('@libs/support/src/lib/support.module').then(
            (m) => m.SupportModule
          ),
      },
      {
        path: 'discounts',
        canActivate: [RoleGuard],
        data: { roles: { whiteList: [OpsRole.Admin] }, title: 'discounts' },
        loadChildren: () =>
          import('@libs/discount/src/lib/discount.module').then(
            (m) => m.DiscountModule
          ),
      },
      {
        path: 'classified-ads',
        canActivate: [RoleGuard],
        data: {
          roles: { whiteList: [OpsRole.Admin, OpsRole.Manager] },
          title: 'classified ads',
        },
        loadChildren: () =>
          import('@libs/classified-ads/src/lib/classified-ads.module').then(
            (m) => m.ClassifiedAdsModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('@libs/account/src/lib/account.module').then(
            (m) => m.AccountModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@libs/profile/src/lib/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'ops-tools',
        loadChildren: () =>
          import('@libs/ops-tools/src/lib/ops-tools.module').then(
            (m) => m.OpsToolsModule
          ),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('@libs/auth/src/lib/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
