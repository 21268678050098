import * as Types from '@gql-types';

import { gql } from '@sceon/apollo-connector';
import { Injectable } from '@angular/core';
import * as Apollo from '@sceon/apollo-connector';
export type DictionaryFragment = Pick<
  Types.Dictionary,
  | '_id'
  | 'key'
  | 'value_en'
  | 'value_it'
  | 'value_de'
  | 'value_es'
  | 'value_fr'
  | 'value_hu'
  | 'value_ro'
>;

export type DictionaryListByLangQueryVariables = Types.Exact<{
  categories?: Types.Maybe<
    | Array<Types.Maybe<Types.DictionaryCategories>>
    | Types.Maybe<Types.DictionaryCategories>
  >;
}>;

export type DictionaryListByLangQuery = {
  public?: Types.Maybe<{
    dictionaryList?: Types.Maybe<Array<Types.Maybe<DictionaryFragment>>>;
  }>;
};

export const DictionaryFragmentDoc = gql`
  fragment Dictionary on Dictionary {
    _id
    key
    value_en
    value_it
    value_de
    value_es
    value_fr
    value_hu
    value_ro
  }
`;
export const DictionaryListByLangDocument = gql`
  query dictionaryListByLang($categories: [DictionaryCategories]) {
    public {
      dictionaryList(categories: $categories) {
        ...Dictionary
      }
    }
  }
  ${DictionaryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DictionaryListByLangGQL extends Apollo.Query<
  DictionaryListByLangQuery,
  DictionaryListByLangQueryVariables
> {
  document = DictionaryListByLangDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
