import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { WINDOW } from '@shared/tokens';
import { EMPTY, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const UL_HEADER_HEIGHT = 55;
const LI_ELEMENT_HEIGHT = 46;

@Directive({
  selector: '[hasOverflow]',
})
export class HasOverflowDirective implements OnInit, AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(WINDOW) private _window: Window
  ) {}

  ngAfterViewInit(): void {
    this.updateElemClassList();
  }

  ngOnInit(): void {
    fromEvent(this._window, 'resize')
      .pipe(debounceTime(250))
      .subscribe(() => this.updateElemClassList());
  }

  updateElemClassList() {
    const subMenuElem =
      this.elementRef.nativeElement.querySelector('.sub-menu');
    const layoutElem =
      this._window.document.body.querySelector('.sc-main-layout');

    const { top } = this.elementRef.nativeElement.getBoundingClientRect();
    const elemHeight =
      subMenuElem.children.length * LI_ELEMENT_HEIGHT + UL_HEADER_HEIGHT;

    if (elemHeight + top > layoutElem?.clientHeight) {
      if (elemHeight >= layoutElem.clientHeight / 2) {
        this.renderer.removeClass(
          this.elementRef.nativeElement,
          'sub-position-top'
        );
        this.renderer.addClass(this.elementRef.nativeElement, 'sub-overflow');
      } else {
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'sub-position-top'
        );
        this.renderer.removeClass(
          this.elementRef.nativeElement,
          'sub-overflow'
        );
      }
    } else {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        'sub-position-top'
      );
    }
  }
}
