import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedConfigsQuery } from '@core/root-store';
import { AsyncPipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {
  private paymentMethods$ = this.store.select(
    SharedConfigsQuery.selectPaymentMethods
  );

  constructor(private store: Store, private asyncPipe: AsyncPipe) {}

  transform(value: string, ...args: unknown[]): string {
    return this.asyncPipe.transform(
      this.paymentMethods$.pipe(
        map((paymentMethods) => {
          if (!paymentMethods || !paymentMethods.length) {
            return value;
          }

          const method = paymentMethods.find(
            (i) => i.name.toLowerCase() === value.toLowerCase()
          );
          return method ? method.display_name : value;
        })
      )
    );
  }
}
