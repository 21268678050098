import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { CellarRoute } from '@libs/core/src/lib/types';
import { AuthService, AuthTokenService } from '@libs/core/src';
import { OpsRole } from '@gql-types';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
  fallbackUrl: string;

  constructor(
    private router: Router,
    private authTokenService: AuthTokenService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    this.setFallbackUrl();
    return (
      this.canAccessRoute(next.data as any) ||
      this.router.createUrlTree([this.fallbackUrl])
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    this.setFallbackUrl();
    return (
      this.canAccessRoute(next.data as any) ||
      this.router.createUrlTree([this.fallbackUrl])
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    this.setFallbackUrl();
    return (
      this.canAccessRoute(route.data as any) ||
      this.router.createUrlTree([this.fallbackUrl])
    );
  }

  private setFallbackUrl() {
    const authRole = this.authTokenService
      .getDecodedToken()
      ?.role.toUpperCase();
    if (authRole === OpsRole.Seo) {
      this.fallbackUrl = '/seo/templates';
    } else {
      this.fallbackUrl = '/dashboard';
    }
  }

  private canAccessRoute(data: CellarRoute['data']) {
    const authRole = this.authTokenService
      .getDecodedToken()
      ?.role.toUpperCase();
    const roles = data.roles;

    if (roles) {
      if (roles.whiteList) {
        return roles.whiteList.includes(authRole);
      }
      if (roles.blackList) {
        return !roles.blackList.includes(authRole);
      }

      return true;
    }

    return true;
  }
}
