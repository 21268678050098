import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

import { ScNotifierService } from '@sceon/notifier';
import { ComponentStore } from '@ngrx/component-store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { CellarAggregatorSearchItem } from '@gql-types';

import {
  CellarAggregatorSearchQueryVariables,
  SearchbarSDK,
} from './_graphql/searchbar';
import { stateLogger } from '@shared/utils';

interface State {
  searchInput: CellarAggregatorSearchQueryVariables;
  result: CellarAggregatorSearchItem[];
  count: number;
  dialogCmp: Type<any>;
  loading: boolean;
}

const INITIAL_STATE: State = {
  searchInput: {
    query: '',
    min_score: null,
  },
  dialogCmp: null,
  result: [],
  count: 0,
  loading: false,
};

@Injectable()
export class SearchbarStore extends ComponentStore<State> {
  constructor(
    private searchbarSDK: SearchbarSDK,
    private scNotifier: ScNotifierService,
    private matDialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    super(INITIAL_STATE);

    this.state$.subscribe(stateLogger('Searchbar'));
  }

  s;
  /**
   * ****************************************************************
   * Selectors
   * ****************************************************************
   * */
  readonly loading$ = this.select((state) => state.loading);

  readonly searchQuery$ = this.select((state) => state.searchInput.query);

  readonly resultCount$ = this.select((state) => state.count);

  readonly searchResult$ = this.select((state) =>
    state.count
      ? state.result.reduce(
          (acc, val) => ({
            ...acc,
            [val.type]: [...(acc[val.type] || []), val],
          }),
          {}
        )
      : {}
  );

  /**
   * ****************************************************************
   * Updaters
   * ****************************************************************
   * */
  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading,
  }));

  readonly setDialogCmp = this.updater((state, dialogCmp: Type<any>) => ({
    ...state,
    dialogCmp,
  }));

  readonly updateSearchQuery = this.updater((state, query: string) => ({
    ...state,
    searchInput: {
      ...state.searchInput,
      query,
    },
  }));

  /**
   * ****************************************************************
   * Effects
   * ****************************************************************
   * */
  readonly search = this.effect(() =>
    this.searchQuery$.pipe(
      filter((query) => !!query),
      tap(() => {
        const cmp = this.get((state) => state.dialogCmp);
        this.matDialog.open(cmp, {
          viewContainerRef: this.viewContainerRef,
          maxWidth: '100%',
          minWidth: '65%',
        });

        this.setLoading(true);
      }),
      switchMap(() =>
        this.searchbarSDK
          .cellarAggregatorSearch(
            this.get(({ searchInput: { query, min_score } }) => ({
              query,
              min_score,
            }))
          )
          .pipe(
            tap(({ data }) => {
              const result = data.ops.cellarAggregatorSearch;

              this.setState((state) => ({
                ...state,
                result: result.result,
                count: result.count,
              }));
            }),
            catchError((err) => {
              this.matDialog.closeAll();

              setTimeout(() => {
                this.scNotifier.error({
                  title: 'Error !',
                  text:
                    err.message || 'An error occurred please try again later.',
                });
              });

              return EMPTY;
            }),
            finalize(() => {
              this.setState((state) => ({
                ...state,
                loading: false,
                searchInput: {
                  ...state.searchInput,
                  query: null,
                },
              }));
            })
          )
      )
    )
  );

  readonly routerChanges = this.effect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      tap(() => {
        this.matDialog.closeAll();
      })
    )
  );
}
