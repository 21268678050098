import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../actions';
import { PendingEntitiesCounts } from '../../types/index';

export const layoutFeatureKey = 'layout';

export interface State {
  showSidenav: boolean;
  badges: PendingEntitiesCounts;
}

const initialState: State = {
  showSidenav: false,
  badges: {},
};

export const reducer = createReducer<State>(
  initialState,
  on(LayoutActions.openSidenav, (state) => ({ ...state, showSidenav: true })),
  on(LayoutActions.closeSidenav, (state) => ({ ...state, showSidenav: false })),
  on(LayoutActions.getBadgeDataSuccess, (state, { badges }) => ({
    ...state,
    badges,
  }))
);
