import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ScFormatDatePipe } from '@shared/pipes/format-date.pipe';
import * as moment from 'moment';

export const intervals = {
  year: 31536000,
  month: 2592000,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};

@Pipe({
  name: 'untilTime',
})
export class UntilTimePipe implements PipeTransform {
  static checkSingleNumber(num: number) {
    return num <= 0 ? '00' : num <= 9 ? '0' + num : num;
  }

  constructor(private formatDate: ScFormatDatePipe) {}

  transform(value: string): Observable<string> {
    if (!value) {
      return of('N/A');
    }
    return interval(60000)
      .pipe(startWith(0))
      .pipe(
        map(() => {
          const now = this.formatDate.transform(new Date());
          const date = this.formatDate.transform(value);
          const delta = moment(now).diff(date, 'seconds');

          if (delta < intervals.hour) {
            return moment(now).diff(date, 'minute') + 'm ago';
          } else if (delta < intervals.day) {
            return moment(now).diff(date, 'hour') + 'h ago';
          } else if (delta < intervals.week) {
            return moment(now).diff(date, 'day') + 'd ago';
          } else {
            return this.formatDate.transform(date, 'DD.MM.YYYY');
          }
        })
      );
  }
}
