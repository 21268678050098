import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import { GalleryViewerComponent } from './gallery-viewer.component';

import {
  Gallery,
  GalleryItem,
  GalleryItemType,
  GalleryItemType as ScGalleryItemType,
  ImageItem,
  ThumbnailsPosition,
  VideoItem,
} from 'ng-gallery';
import { Lightbox, LightboxConfig } from 'ng-gallery/lightbox';

@Injectable()
class ScGalleryViewer {
  constructor(
    private gallery: Gallery,
    private lightbox: Lightbox,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  open(
    data: Array<{ type: ScGalleryItemType; src: string; thumb?: string }>,
    index: number = 0,
    config?: LightboxConfig
  ): void {
    const items: GalleryItem[] = data.map(({ type, src, thumb }) => {
      if (type === ScGalleryItemType.Image) {
        return new ImageItem({ src });
      }
      if (type === ScGalleryItemType.Video) {
        return new VideoItem({
          src: [{ type: '', url: src }] as any,
        });
      }
    });

    this.gallery
      .ref(null, { gestures: false, counter: items.length > 1 })
      .load(items);

    this.lightbox.open(index, null, config);
  }

  openWithZoom(
    data: Array<{
      type?: ScGalleryItemType;
      src: string;
      originalSrc?: string;
    }>,
    index: number = 0,
    config?: LightboxConfig
  ): void {
    const componentFactory =
      this.resolver.resolveComponentFactory<GalleryViewerComponent>(
        GalleryViewerComponent
      );

    const otfComponent = componentFactory.create(this.injector);

    const items: GalleryItem[] = data.map((data) => ({
      data: {
        src:
          data.type === GalleryItemType.Video
            ? [{ type: '', url: data.src }]
            : data.src,
        original: data.originalSrc || null,
      },
      type:
        data.type && data.type !== ScGalleryItemType.Image
          ? data.type
          : 'imageViewer',
    }));

    this.gallery
      .ref(null, {
        counter: items.length > 1,
        itemTemplate: otfComponent.instance.template,
        thumbPosition: ThumbnailsPosition.Top,
        gestures: false,
      })
      .load(items);

    this.lightbox.open(index, null, config);
  }

  /**
   * Reset all gallery instances
   */
  resetAll(): void {
    return this.gallery.resetAll();
  }

  /**
   * Destroy all gallery instances
   */
  destroyAll(): void {
    return this.gallery.destroyAll();
  }
}

export { ScGalleryItemType, ScGalleryViewer };
