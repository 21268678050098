import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class TitleEffects {
  constructor(
    private actions$: Actions,
    private titleService: Title,
    private titleCase: TitleCasePipe
  ) {}

  updateTitle = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map(({ payload }) => this.createTitle(payload.routerState.root)),
        tap((title) => {
          this.titleService.setTitle(title || '**');
        })
      ),
    { dispatch: false }
  );

  private createTitle(route: ActivatedRouteSnapshot): string {
    const resolvedTitlesSet = new Set<string>();

    while (route.firstChild) {
      route = route.firstChild;

      if (route.data.title) {
        resolvedTitlesSet.add(route.data.title);
      }
    }

    let title = '';

    [...resolvedTitlesSet].forEach((value, index) => {
      if (index === 0) {
        title += this.titleCase.transform(value);
      } else {
        title += ` - ${this.titleCase.transform(value)}`;
      }
    });

    return `${title} | And6 Cellar`;
  }
}
