// Angular specific
import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  TemplateRef,
} from '@angular/core';

// Material specific
import { ThemePalette } from '@angular/material/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

// Module specific
import { ScConfirmDialogComponent } from './confirm-dialog.component';
import { ScMapperPipeFn } from '@shared/pipes';

export interface ScConfirmDialogOptions {
  title: string;
  textOrTemplateRef: string | TemplateRef<any>;
  buttonText: string;
  buttonStyle: ThemePalette;
}

export const SC_CONFIRM_DIALOG_DEFAULT_OPTIONS = new InjectionToken<any>(
  'SC_CONFIRM_DIALOG_DEFAULT_OPTIONS'
);

@Injectable({
  providedIn: 'root',
})
export class ScConfirmDialog {
  constructor(
    private matDialog: MatDialog,
    @Optional()
    @Inject(SC_CONFIRM_DIALOG_DEFAULT_OPTIONS)
    private defaultOptions: ScConfirmDialogOptions
  ) {}

  show(
    options: ScConfirmDialogOptions
  ): MatDialogRef<ScConfirmDialogComponent, boolean> {
    return this.matDialog.open(ScConfirmDialogComponent, {
      data: options,
      panelClass: ['half-width-sm'],
    });
  }

  hide() {
    this.matDialog.closeAll();
  }
}
