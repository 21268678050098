// Module specific
import { Menu } from './layout.types';
import { OpsRole } from '@gql-types';
import { PendingEntityIdentifier } from '@core/types';

export const menuItems: Array<Menu.Item> = [
  {
    id: Menu.ItemIdentifier.Dashboard,
    icon: 'home',
    title: 'Dashboard',
    path: '/dashboard',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
  },
  {
    id: Menu.ItemIdentifier.Search,
    icon: 'search',
    title: 'Search',
    path: null,
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        id: Menu.ItemIdentifier.ProfileSearch,
        title: 'Escorts',
        path: '/profile-search',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.CompanyProfileSearch,
        title: 'Agencies',
        path: '/company-profile-search',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.MemberProfileSearch,
        title: 'Members',
        path: '/member-profile-search',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.PersonalUrl,
        title: 'Personal URL',
        path: '/persoanl-url',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        id: Menu.ItemIdentifier.AgeVerification,
        title: 'Age Certifications',
        path: '/age-verification',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.HundredVerification,
        title: '100% Verifications',
        path: '/hundred-verification',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.NotVerifiedAccounts,
        title: 'Not Verified Accounts',
        path: '/not-verified-accounts',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.BannedAccounts,
        title: 'Banned Accounts',
        path: '/banned-accounts',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        id: Menu.ItemIdentifier.Chat,
        title: 'Chat',
        path: '/chat-search',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        id: Menu.ItemIdentifier.IpCookieDublicities,
        title: 'Ip / Cookie Duplicities',
        path: '/ip-cookie-duplicities',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        id: Menu.ItemIdentifier.Banners,
        title: 'Banners',
        path: '/banners',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        id: Menu.ItemIdentifier.StatusMessages,
        title: 'Status Messages',
        path: '/status-messages',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Ticket,
    icon: 'tickets',
    title: 'To do',
    path: '/tickets',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        path: 'new-registered-profiles',
        title: 'New Profiles',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.NewRegisteredProfiles,
      },
      {
        path: 'new-registered-agencies',
        title: 'New Agencies',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.NewRegisteredAgencies,
      },
      {
        path: 'pending-profile-updates',
        title: 'Profile Updates',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.ProfileUpdates,
      },
      {
        path: 'pending-agency-profile-updates',
        title: 'Agency Profile Updates',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.AgencyProfileUpdates,
      },
      {
        path: 'pending-photos',
        title: 'Photos',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.Photos,
      },
      {
        path: 'pending-agency-photos',
        title: 'Agency Photos',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.AgencyPhotos,
      },
      // {
      //   path: 'approved-photos-overview',
      //   title: 'Approved Photos Overview',
      //   roles: [OpsRole.Admin, OpsRole.Manager],
      // },
      {
        path: 'pending-age-verifications',
        title: 'Age Certifications',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.AgeVerifications,
      },
      {
        path: 'pending-hundred-percent-verifications',
        title: '100% Verifications',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.OneHundredVerifications,
      },
      {
        path: 'pending-stories',
        title: 'Stories',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.Stories,
      },
      {
        path: 'pending-videos',
        title: 'Videos',
        roles: [OpsRole.Admin, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.Videos,
      },
      {
        path: 'pending-comments',
        title: 'Comments',
        roles: [OpsRole.Admin, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.Comments,
      },
      {
        path: 'pending-agency-comments',
        title: 'Agency Comments',
        roles: [OpsRole.Admin, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.AgencyComments,
      },
      {
        path: 'top-10-comments',
        title: 'Top 10 Comments',
        absolutePath: true,
        queryParams: {
          s: 'PENDING',
        },
        roles: [OpsRole.Admin, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.Top10Comments,
      },
      {
        path: 'pending-payments',
        title: 'Transfers',
        roles: [OpsRole.Admin],
        badgeIdentifier: PendingEntityIdentifier.PendingPayments,
      },
      {
        id: Menu.ItemIdentifier.ClientBlacklist,
        icon: 'block',
        path: 'client-blacklist/escorts-list',
        title: 'Client Blacklist',
        roles: [OpsRole.Admin, OpsRole.Manager],
        queryParams: {
          status: 'pending',
        },
        absolutePath: true,
        badgeIdentifier: PendingEntityIdentifier.ClientBlacklist,
      },
      {
        id: Menu.ItemIdentifier.Banners,
        icon: 'block',
        path: 'banners',
        title: 'Banners',
        roles: [OpsRole.Admin, OpsRole.Manager],
        queryParams: {
          s: 'PENDING',
        },
        absolutePath: true,
        badgeIdentifier: PendingEntityIdentifier.Banners,
      },
      {
        path: 'pending-sales-change-requests',
        title: 'Sales Change Requests',
        roles: [OpsRole.Admin, OpsRole.Sales, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.SalesChangeRequests,
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.AIModeration,
    icon: 'ai',
    title: 'AI Moderated',
    path: '/ai-moderated',
    roles: [OpsRole.Admin, OpsRole.Manager],
    subMenu: [
      {
        path: 'profile-overview',
        title: 'Profile Overview',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'company-profile-overview',
        title: 'Company Profile Overview',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'story-overview',
        title: 'Story Overview',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'classified-ad-overview',
        title: 'Classified Ad Overview',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Support,
    icon: 'support',
    title: 'Support',
    path: '/support',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        path: 'issues',
        title: 'Issues',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
        badgeIdentifier: PendingEntityIdentifier.Issues,
        queryParams: {
          status: 'pending',
        },
      },
      {
        path: 'feedbacks',
        title: 'Feedbacks',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'profile-problem-reports',
        title: 'Problem Reports',
        roles: [OpsRole.Admin, OpsRole.Manager],
        badgeIdentifier: PendingEntityIdentifier.ProfileProblemReports,
      },
      {
        path: 'faq',
        title: 'F.A.Q.',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Billing,
    icon: 'payment',
    title: 'Billing',
    path: '/billing',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        path: 'transfers',
        title: `Transfers`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'orders',
        title: `Orders`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'packages',
        title: `Packages`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'gotds',
        title: `GOTDS`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'votds',
        title: `VOTDS`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'status-messages',
        title: `Status Messages`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'classified-ads',
        title: `Classified Ads`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'banners',
        title: `Banners`,
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      // {
      //   path: 'monthly-sales-reports',
      //   title: `Monthly Sales Reports`,
      //   roles: [OpsRole.Admin],
      // },
    ],
  },
  {
    id: Menu.ItemIdentifier.ClassifiedAds,
    icon: 'ads',
    title: 'Classified ads',
    path: '/classified-ads',
    queryParams: {
      s: 'PENDING',
    },
    roles: [OpsRole.Admin, OpsRole.Manager],
  },
  {
    id: Menu.ItemIdentifier.SmsAndEmail,
    icon: 'forum',
    title: 'SMS and Email',
    path: '/messages',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        path: 'sms-inbox',
        title: 'SMS Inbox',
      },
      {
        path: 'sms-outbox',
        title: 'SMS Outbox',
      },
      {
        path: 'email',
        title: 'Email Outbox',
      },
      {
        path: 'contact-groups',
        title: 'Contact Groups',
        roles: [OpsRole.Admin],
      },
      {
        path: 'contacts',
        title: 'Contacts',
        roles: [OpsRole.Admin],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.ClientBlacklist,
    icon: 'block',
    title: 'Client Blacklist',
    path: '/client-blacklist',
    roles: [OpsRole.Admin, OpsRole.Manager],
    subMenu: [
      {
        path: 'escorts-list',
        title: `Escort's List`,
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'admins-list',
        title: `Admin's List`,
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Newsletter,
    icon: 'newsletter',
    title: 'Newsletter',
    path: '/newsletter',
    roles: [OpsRole.Admin, OpsRole.Manager],
    subMenu: [
      {
        path: 'templates',
        title: 'Templates',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'prototypes',
        title: 'Prototypes',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'online-news',
        title: 'Online News',
        roles: [OpsRole.Admin /*OpsRole.Manager*/],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Template,
    icon: 'template',
    title: 'Template',
    path: '/templates',
    roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
    subMenu: [
      {
        path: 'activity-templates',
        title: 'Activity Templates',
        roles: [OpsRole.Admin],
      },
      {
        path: 'regular-templates',
        title: 'Regular Templates',
        roles: [OpsRole.Admin, OpsRole.Manager, OpsRole.Sales],
      },
      {
        path: 'system-sms-templates',
        title: 'System SMS Templates',
        roles: [OpsRole.Admin],
      },
      {
        path: 'system-pn-templates',
        title: 'System PN Templates',
        roles: [OpsRole.Admin],
      },
      {
        path: 'system-email-templates',
        title: 'System Email Templates',
        roles: [OpsRole.Admin],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Seo,
    icon: 'seo',
    title: 'Seo',
    path: '/seo',
    roles: [],
    subMenu: [
      {
        path: 'templates',
        title: 'Templates',
        roles: [OpsRole.Admin, OpsRole.Seo],
      },
      {
        path: 'template-instances',
        title: 'Template instances',
        roles: [OpsRole.Admin, OpsRole.Seo],
      },
      {
        path: 'robots.txt',
        title: 'Robots',
        roles: [OpsRole.Admin, OpsRole.Seo],
      },
      {
        path: 'not-found',
        title: '404 Logging',
        roles: [],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Discount,
    icon: 'percent',
    title: 'Discounts',
    path: '/discounts',
    roles: [OpsRole.Admin],
  },
  {
    id: Menu.ItemIdentifier.System,
    icon: 'options',
    title: 'System',
    path: '/system/',
    roles: [OpsRole.Admin, OpsRole.Manager],
    subMenu: [
      {
        path: 'dictionary',
        title: 'Dictionary',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'decline-reasons',
        title: 'Decline Reasons',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'blacklisted-entities',
        title: 'Blacklisted Entities',
        roles: [OpsRole.Admin, OpsRole.Manager],
      },
      {
        path: 'cellar-users',
        title: 'Cellar Users',
        roles: [OpsRole.Admin],
      },
    ],
  },
  {
    id: Menu.ItemIdentifier.Logout,
    icon: 'logout',
    title: 'Logout',
    roles: [],
  },
];
