// Angular specific
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Third party
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
} from '@angular/material/legacy-form-field';
import {
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import {
  MAT_LEGACY_PROGRESS_SPINNER_DEFAULT_OPTIONS as MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
  MatLegacyProgressSpinnerDefaultOptions as MatProgressSpinnerDefaultOptions,
} from '@angular/material/legacy-progress-spinner';
import {
  MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatLegacyPaginatorDefaultOptions as MatPaginatorDefaultOptions,
} from '@angular/material/legacy-paginator';

import { NgxMaskModule } from 'ngx-mask';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { IScrollbarOptions } from 'ngx-scrollbar/lib/ng-scrollbar.model';
import { disableFragmentWarnings } from 'graphql-tag';

// Project specific
import { ApolloConnectorModule } from '@sceon/apollo-connector';
import { ScNotifierModule, ScNotifierTheme } from '@sceon/notifier';
import { ScConfirmDialogModule } from '@shared/confirm-dialog';
import { SC_GALLERY_CONFIG_PROVIDER } from '@shared/gallery-viewer';
import {
  provideErrorMsgHashMap,
  defaultErrorsMap,
} from '@shared/form-error-message';

// Core specific
import { RootStoreModule } from './root-store';
import { LayoutModule } from './layout';
import {
  AND6_APOLLO_CONNECTOR_PROVIDER,
  AND6_GLOBAL_ERROR_HANDLER_PROVIDER,
  AND6_SENTRY_PROVIDERS,
} from './providers';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { CoreComponent } from './core.component';

// disable: dynamic fragments and the unique fragments warning
// warning: fragment with name 'some_name' already exists.
// graphql-tag enforces all fragment names across your application to be unique
disableFragmentWarnings();

@NgModule({
  declarations: [CoreComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    MatSnackBarModule,
    MatMomentDateModule,
    // MatDatepickerModule,
    RouterModule,
    RootStoreModule,
    ApolloConnectorModule.forBrowser(),
    LayoutModule,
    NgxMaskModule.forRoot(),
    ScConfirmDialogModule.forRoot(),
    ScNotifierModule.forRoot(
      {
        cancelIcon: true,
        titleIcon: 'sc sc-alerts-selected',
        theme: ScNotifierTheme.Primary,
      },
      {
        multiple: true,
        pauseOnHover: true,
      }
    ),
    TranslocoRootModule,
  ],
  providers: [
    AND6_SENTRY_PROVIDERS,
    AND6_GLOBAL_ERROR_HANDLER_PROVIDER,
    AND6_APOLLO_CONNECTOR_PROVIDER,
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        autoHeightDisabled: false,
        appearance: 'standard',
        visibility: 'hover',
      } as Partial<IScrollbarOptions>,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        closeOnNavigation: true,
        width: '676px',
        hasBackdrop: true,
      } as MatDialogConfig,
    },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
        _forceAnimations: false,
        diameter: 30,
        strokeWidth: 3,
      } as MatProgressSpinnerDefaultOptions,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'standard',
        hidePageSize: false,
        pageSizeOptions: [10, 20, 30],
        showFirstLastButtons: true,
        pageSize: 10,
      } as MatPaginatorDefaultOptions,
    },
    SC_GALLERY_CONFIG_PROVIDER,
    provideErrorMsgHashMap(defaultErrorsMap),
  ],
  exports: [CoreComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
