import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';

import {
  init,
  routingInstrumentation,
  createErrorHandler,
  TraceService,
} from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { environment } from '@env/environment';
import { SentryIssueScope } from '@libs/core/src/lib/services';

export const ignoreErrorMessages: string[] = ['ResizeObserver'];

export const AND6_SENTRY_PROVIDERS: Provider[] = [
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
      logErrors: false,
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {
      init({
        /** @link https://docs.sentry.io/product/sentry-basics/dsn-explainer/#dsn-utilization */
        dsn: environment.sentryDsn,
        /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#environment */
        environment: environment.environmentName,
        /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#tracing-options */
        tracesSampleRate: 0.8,
        /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/ */
        maxBreadcrumbs: 5,
        beforeSend: (event) => {
          if (!event.message) {
            return null;
          }

          if (
            ignoreErrorMessages.find((im) =>
              event.message.toLowerCase().includes(im.toLowerCase())
            )
          ) {
            return null;
          }

          if (!Object.values(SentryIssueScope).includes(event.message as any)) {
            event.extra = { message: event.message };
            event.message = SentryIssueScope.ClientSideError;
            delete event.exception;
          }

          return event;
        },
        integrations: [
          new BrowserTracing({
            idleTimeout: 10000,
            tracingOrigins: [
              'localhost',
              environment.cellarUrl,
              environment.apiUrl,
            ],
            routingInstrumentation,
          }),
        ],
      });
    },
    deps: [TraceService],
    multi: true,
  },
];
