<div mat-dialog-title>
  <h4>{{ data.title }}</h4>
</div>

<div mat-dialog-content>
  <ng-container
    *ngIf="!(data.textOrTemplateRef | mapper: stringMapperFn)"
    [ngTemplateOutlet]="$any(data.textOrTemplateRef)"
  ></ng-container>
  <span *ngIf="data.textOrTemplateRef | mapper: stringMapperFn">{{
    data.textOrTemplateRef
  }}</span>
</div>

<div mat-dialog-actions class="hide-btn-border-sm">
  <div class="main-actions">
    <button
      mat-button
      class="cancel-button sm"
      tabindex="-1"
      (click)="cancel()"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      tabindex="-1"
      class="action-button sm text-uppercase"
      [color]="data.buttonStyle"
      (click)="confirm()"
    >
      {{ data.buttonText }}
    </button>
  </div>
</div>
