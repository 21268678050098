import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

interface ResizeObserver {
  observe(target: Element): void;

  unobserve(target: Element): void;

  disconnect(): void;
}

type Target = 'width' | 'height';

@Directive({
  selector: '[scAspectRatio]',
})
export class ScAspectRatioDirective implements OnInit, OnDestroy {
  /**
   * Directive Destroy
   */
  destroy$ = new Subject<void>();
  /**
   * Element Width
   */
  targetSize$ = new BehaviorSubject<number>(0);
  /**
   * Resize Observer
   */
  observer!: ResizeObserver;
  /**
   * Aspect Ratio
   * @example 1/1, 3/4, 16/9
   */
  @Input() scAspectRatio: number = 3 / 4;

  /**
   * Target
   * Edge target witch should be taken for calculating sizes.
   */
  @Input() target: Target = 'width';

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    // @ts-ignore
    this.observer = new ResizeObserver((entries) => {
      this.targetSize$.next(entries[0].contentRect[this.target]);
    });

    this.observer.observe(this.elRef.nativeElement);
    this.targetSize$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap((size) => {
          const reversed = this.getTargetReverse(this.target);
          this.elRef.nativeElement.style[reversed] =
            this.target === 'width'
              ? size / this.scAspectRatio + 'px'
              : size * this.scAspectRatio + 'px';
        })
      )
      .subscribe();
  }

  private getTargetReverse(target: Target): Target {
    return target === 'width' ? 'height' : 'width';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.observer.unobserve(this.elRef.nativeElement);
  }
}
