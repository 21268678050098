import { EMPTY, Observable, noop } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

export function tapResponse<T>(
  nextFn: (next: T) => void,
  errorFn: (error: unknown) => void,
  completeFn?: () => void
): (source: Observable<T>) => Observable<T> {
  return (source) =>
    source.pipe(
      tap(nextFn),
      catchError((error) => {
        errorFn(error);
        return EMPTY;
      }),
      finalize(completeFn || noop)
    );
}

// export function tapResponse<T>(
//   nextFn: (next: T) => void,
//   errorFn: (error: unknown) => void,
//   completeFn?: () => void
// ): (source: Observable<T>) => Observable<T> {
//   return (source) =>
//     source.pipe(
//       tap({
//         next: nextFn,
//         error: errorFn,
//         complete: completeFn,
//       }),
//       catchError(() => EMPTY)
//     );
// }
