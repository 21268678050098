import { InjectionToken } from '@angular/core';
import { OpsAccount } from '@gql-types';
import { environment } from '@env/environment';
import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  Action,
  ActionReducerMap,
} from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages-container up all of the exports into a single object.
 */
import * as fromRouter from '@ngrx/router-store';
import * as fromLayout from './layout.reducer';
import * as fromGqlWs from './gql-ws.reducer';
import * as fromOpsAccount from './ops-account.reducer';
import * as fromSharedConfigs from './shared-configs.reducer';
import { Params } from '@angular/router';

/**
 * Our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState<any /*RouterStateUrl*/>;
  [fromGqlWs.graphqlWebsocketFeatureKey]: fromGqlWs.State;
  [fromLayout.layoutFeatureKey]: fromLayout.State;
  [fromOpsAccount.opsAccountFeatureKey]: fromOpsAccount.State;
  [fromSharedConfigs.SharedConfigsFeatureKey]: fromSharedConfigs.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
    [fromLayout.layoutFeatureKey]: fromLayout.reducer,
    [fromGqlWs.graphqlWebsocketFeatureKey]: fromGqlWs.reducer,
    [fromOpsAccount.opsAccountFeatureKey]: fromOpsAccount.reducer,
    [fromSharedConfigs.SharedConfigsFeatureKey]: fromSharedConfigs.reducer,
  }),
});

/**
 * Default logger for all actions
 * */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);

    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

/**
 * Router state selectors
 * */
export namespace routerQuery {
  export const selectRouterState =
    createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

  export const {
    selectCurrentRoute,
    selectFragment,
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
  } = fromRouter.getRouterSelectors(selectRouterState);

  export const selectRouteNestedParams = createSelector(
    selectRouterState,
    (router) => {
      let currentRoute = router?.state?.root;
      let params: Params = {};
      while (currentRoute?.firstChild) {
        currentRoute = currentRoute.firstChild;
        params = {
          ...params,
          ...currentRoute.params,
        };
      }
      return params;
    }
  );

  export const selectRouteNestedParam = (param: string) =>
    createSelector(
      selectRouteNestedParams,
      (params) => params && params[param]
    );
}

/**
 * Gql websocket state selectors
 */
export namespace graphqlWebsocketQuery {
  export const selectGraphqlWebsocketState = createFeatureSelector<
    State,
    fromGqlWs.State
  >(fromGqlWs.graphqlWebsocketFeatureKey);

  export const selectStatus = createSelector(
    selectGraphqlWebsocketState,
    fromGqlWs.selectStatus
  );

  export const selectError = createSelector(
    selectGraphqlWebsocketState,
    fromGqlWs.selectError
  );
}

/**
 * Layout state selectors
 * */
export namespace layoutQuery {
  export const selectLayoutState =
    createFeatureSelector<fromLayout.State>('layout');

  export const selectShowSidenav = createSelector(
    selectLayoutState,
    (state) => state.showSidenav
  );

  export const selectBadges = createSelector(
    selectLayoutState,
    (state) => state.badges
  );
}

/**
 * Shared Configs state selectors
 * */
export namespace SharedConfigsQuery {
  export const selectSharedConfigsState =
    createFeatureSelector<fromSharedConfigs.State>('shared-configs');

  export const selectPaymentMethods = createSelector(
    selectSharedConfigsState,
    (state) => state.paymentMethods
  );
}

/**
 * Ops account state selectors
 * */
export namespace opsAccountQuery {
  export const selectOpsAccountState =
    createFeatureSelector<fromOpsAccount.State>('ops-account');

  export const { selectAll, selectEntities, selectIds, selectTotal } =
    fromOpsAccount.adapter.getSelectors(selectOpsAccountState);

  export const selectLoading = createSelector(
    selectOpsAccountState,
    (selectOpsAccountState) => selectOpsAccountState.loading
  );
  export const selectError = createSelector(
    selectOpsAccountState,
    (selectOpsAccountState) => selectOpsAccountState.error
  );

  export const selectById = createSelector(
    selectEntities,
    (entities: { [id: number]: OpsAccount }, props: { id: number }) =>
      entities[props.id]
  );
}
